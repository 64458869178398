/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import iconHome from '../../../assets/web/mobile-home.png';
import iconNewsfeed from '../../../assets/newsfeed.svg';
import iconChat from '../../../assets/chat.svg';
import iconSpin from '../../../assets/spin.svg';
import iconRewards from '../../../assets/rewards.svg';
import iconFantasy from '../../../assets/fantasy.svg';
import iconHistory from '../../../assets/history.svg';
import iconLeaderboard from '../../../assets/leaderboard.svg';
import iconLeaderboardActive from '../../../assets/leaderboard-active.svg';
import iconPayment from '../../../assets/payment.svg';
import iconAgent from '../../../assets/agent.svg';
import iconRefer from '../../../assets/refer.svg';
import iconLogout from '../../../assets/logout.svg';
import iconLogin from '../../../assets/girl-user.png';
import iconVip from '../../../assets/vip.png'
import iconSettings from '../../../assets/setting.svg'

import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated, isData } from '../../../redux/selectors/authSelector';
import { loginClear, becomeAgent } from '../../../redux/actions/authActions';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const MainMenu = (props) => {
  const Authenticated = useSelector(isAuthenticated);
  const ProfileData = useSelector(isData)
  const [open, setOpen] = useState('');

  const dispatch = useDispatch();
  const LogoutAction = () => {
    dispatch(loginClear());
  }

  const setOpencall = (e) => {
    if (open == e) { setOpen(''); } else {
      setOpen(e);
    }
  }

  const checkMenuOpen = () => {
    try {
      if (!document.getElementById('bottom-menu').classList.contains('collapsed')) {
        document.getElementById('bottom-menu').click();
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (Authenticated) checkMenuOpen();
  }, [Authenticated]);

  return (
    <>
      <p className='mobile-view top-menu-text'>Menu</p>

      <Link to={'/'} onClick={() => checkMenuOpen()}><img src={iconHome} /> Home</Link>
      {!Authenticated ? (
        <>
          <Link to={'/feed'} onClick={() => checkMenuOpen()}><img src={iconNewsfeed} /> News Feed</Link>
          <Link to={'/chat'} onClick={() => checkMenuOpen()}><img src={iconChat} /> Chat</Link>
        </>
      ) : (((Authenticated) && (ProfileData.user.allow_social_media)) ? (
          <>
            <Link to={'/feed'} onClick={() => checkMenuOpen()}><img src={iconNewsfeed} /> News Feed</Link>
            <Link to={'/chat'} onClick={() => checkMenuOpen()}><img src={iconChat} /> Chat</Link>
          </>
        ) : ''
      ) }

      <Link to={'/spin-wheel'} onClick={() => checkMenuOpen()}><img src={iconSpin} /> Spin Wheel</Link>
      {/* <Link to={'/vip-club'} onClick={() => checkMenuOpen()}><img src={iconVip} /> VIP Club</Link> */}
      <Link to={'/rewards'} onClick={() => checkMenuOpen()}><img src={iconRewards} /> Rewards</Link>
      <Link to={'/fantasy'} onClick={() => checkMenuOpen()}><img src={iconFantasy} /> Fantasy</Link>
      <Link to={'/game-history'} onClick={() => checkMenuOpen()}><img src={iconHistory} /> Game History</Link>

      <div onClick={() => setOpencall('leaderboard')} className={open == 'leaderboard' ? `active` : ``}><img src={open == 'leaderboard' ? iconLeaderboardActive : iconLeaderboard} /> <span>Leaderboard</span>
        <span className='sub-menu'>
          <Link to={'/global-leaderboard'} onClick={() => checkMenuOpen()}>Global</Link>
          <Link to={'/friends-leaderboard'} onClick={() => checkMenuOpen()}>Friends</Link>
        </span>
      </div>

      <Link to={'/coins/transactions'} onClick={() => checkMenuOpen()}><img src={iconPayment} /> Payments</Link>
      {Authenticated ? '' : <a href="#" onClick={() => dispatch(becomeAgent())}><img src={iconAgent} /> Become an Agent</a>}
      <Link to={'/refer-earn'} onClick={() => checkMenuOpen()}><img src={iconRefer} /> Refer & Earn</Link>

      <div onClick={() => setOpencall('settings')} className={open == 'settings' ? `active` : ``}><img src={open == 'settings' ? iconSettings : iconSettings} /> <span>Settings</span>
        <span className='sub-menu'>
          <Link to={'/vip-club'} onClick={() => checkMenuOpen()}>VIP Club</Link>
          <Link to={'/page/privacy-policy'} onClick={() => checkMenuOpen()}>Privacy Policy</Link>
          <Link to={'/page/terms-conditions'} onClick={() => checkMenuOpen()}>Terms & Conditions</Link>
        </span>
      </div>

      {Authenticated
        ? <>
          <a className='mobile-view' onClick={() => LogoutAction()} href="#"><img src={iconLogout} /> Logout</a>
        </>
        : <>
          <a className='mobile-view' onClick={props.LoginPopup}><img src={iconLogin} /> Sign In / Sign Up</a>
          {/* <div className='mobile-sub-btns'>
                        <button onClick={props.LoginPopup} className='btn primary'>Login</button>
                        <button onClick={props.RegisterPopup} className='btn secondary'>Register</button>
                    </div> */}
        </>}
    </>
  )
}

export default MainMenu;