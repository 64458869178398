/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { login, loginClear } from "../../../redux/actions/authActions";
import { isError, isLoading, isAuthenticated } from "../../../redux/selectors/authSelector"

import Loader from '../Loader';
import Alerts from '../Alert';

const LoginModal = (props) => {
  const [validated, setValidated] = useState(false);
  const [username, setusername] = useState(window.localStorage.getItem('username') || '');
  const [password, setpassword] = useState(window.localStorage.getItem('password') || '');
  const [checked, setchecked] = useState((window.localStorage.getItem('remember') === 'true') || false);

  const errorMsg = useSelector(isError);
  const Authenticated = useSelector(isAuthenticated);
  const loader = useSelector(isLoading);

  const [eye, seteye] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginClear());
  }, []);

  useEffect(() => {
    if (Authenticated) {
      props.closeModal();
    }
  }, [Authenticated]);


  const onLoginAction = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      dispatch(login({ username: username, password: password, remember: checked, device_type : 'Web', device_app_version : '1.0' }));
    }
    setValidated(true);
  };

  return (
    <div className="igame-modal">
      <div className='row'>
        <div className='col-md-6'>
          <div className='leftSidebar'>
            <img src={require('../../../assets/igame-logo.png')} alt="" />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='rightSidebar'>
            <h3>Play your favorite games</h3>
            <h4>with Sign In</h4>

            {errorMsg &&
              <Alerts variant="danger">
                {errorMsg}
              </Alerts>
            }

            <Form noValidate validated={validated} autoComplete="off" method='get' action="/" onSubmit={onLoginAction}>
              <Form.Group controlId="Form.ControlInput1" className="custom-input">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  value={username}
                  onChange={(e) => setusername((e.target.value).toString().trim())}
                  autoComplete="new-username"
                  autoFocus
                  required
                />
                <Form.Control.Feedback type="invalid">Please enter the Username.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="Form.ControlInput2" className="custom-input">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={eye ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  autoComplete="new-password"
                  required
                />
                <b onClick={() => seteye(!eye)}>
                  {eye ?
                    <span className="material-icons">visibility_off</span>
                    :
                    <span className="material-icons">visibility</span>
                  }
                </b>
                <Form.Control.Feedback type="invalid">Please enter the Password.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="check-concert">
                <Form.Check
                  checked={checked}
                  onChange={(e) => setchecked(e.target.checked)}
                  id='inline-login'
                  label="Remember me?"
                />
              </Form.Group>

              <div className='forgot'>
                <a onClick={props.forgotModal}>Forgot Username/Password?</a>
                {/* <a onClick={props.forgotUsernameModal}>Forgot UserName?</a> */}
              </div>

              <Button type='submit' className='primary' disabled={loader}>{loader ? <Loader fullscreen="forms" /> : 'Sign In'}</Button>
              <Button type='button' onClick={props.registerModal} className='secondary'>Sign Up</Button>


              <div className='contact'>
                <a onClick={props.contactUsModal}>Contact us</a>
              </div>
              <div style={{ background: "#403969", padding: "10px 0px", marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", alignItems: "center" }}>
                  <p className='text-end' style={{ fontSize: "10px", margin: "0", color: "#9696AD" }}>
                    <b style={{ display: 'block', minWidth: '65px' }}>10 Lakh</b> Trusted User
                  </p>
                  <img className='login-img login-small-icon' src={require('../../../assets/user.png')} alt="" />
                  <img src={require('../../../assets/secured.png')} alt="" />
                  <img src={require('../../../assets/prize.png')} alt="" className='login-img login-small-icon' />
                  <p className='text-end' style={{ fontSize: "10px", margin: "0", color: "#9696AD" }}>
                    <b style={{ display: 'block', minWidth: '65px' }}>100+ Crore</b> Monthly
                  </p>
                  <p>
                  </p>
                </div>
                <div className='footer p-0 m-0'>
                  <div className='app-store-icons'>
                    <a href='#' >
                      <img src={require('../../../assets/web/store-android.png')} className='android-icon' />
                    </a>
                    <a href='#'>
                      <img src={require('../../../assets/web/store-ios.png')} className='ios-icon' />
                    </a>
                  </div>
                </div>

              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginModal;