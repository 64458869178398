import Moment from 'react-moment';
import moment from 'moment';

export function ConvertDatewithAGO(times) {
  if (!times) times = '';
  const today_date = moment().add(0, 'days').format('DD MMMM YYYY').toString();
  const yeaster_date = moment().add(-1, 'days').format('DD MMMM YYYY').toString();
  const api_date = moment(new Date(times)).add(0, 'days').format('DD MMMM YYYY').toString();
  if (today_date === api_date) return <time>Today</time>;
  if (yeaster_date === api_date) return <time>Yesterday</time>;

  return (
    <Moment format={'DD MMM, YYYY'}>
      {new Date(times)}
    </Moment>
  );
}

export function ConvertYMD(times) {
  if (!times) times = '';
  return times.slice(0, 10);
}

export function ConvertTime(times, format = false) {
  const momentFormat = (format) ? format : 'DD MMM, hh:mm a';
  if (!times) return '';

  return (
    <Moment format={momentFormat}>
      {new Date(times)}
    </Moment>
  );
}
export function ConvertDate(times, format = false) {
  const momentFormat = (format) ? format : 'DD MMM, YYYY';
  if (!times) return '';

  return (
    <Moment format={momentFormat}>
      {new Date(times)}
    </Moment>
  );
}

export function ConvertOnlyTime(times, format = false) {
  const momentFormat = (format) ? format : 'hh:mm a';
  if (!times) return '';

  return (
    <Moment format={momentFormat}>
      {new Date(times)}
    </Moment>
  );
}

export function pagetop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  return false;
}

export function Timerhhmm(value) {
  if (!value) value = 0;
  let total_time = new Date(value * 1000).toISOString().substring(11, 19);
  if (total_time.substring(0, 3) == "00:") {
    total_time = total_time.substring(3);
  }
  return total_time;
}

export function numberWithCommas(value) {
  if (!value) value = 0;
  return parseFloat(value).toLocaleString('en-IN', {
    maximumFractionDigits: 2,
  });
}

export function capitalize(text) {
  return text.replace(/\b\w/g, function (m) { return m.toUpperCase(); });
}

export function chatTypes(i, preview) {
  let content = '';
  let html = false;
  if (i.type === 'text') content = i.content;
  if (i.type === 'file') {
    if (i.content.match(/\.(jpg|jpeg|png|gif)$/)) {
      content = '<img src="' + i.content + '" />';
    } else if (i.content.match(/\.(mp3)$/)) {
      content = '<audio controls><source src="' + i.content + '" type="audio/mp3"></audio>';
    } else {
      content = '<a target="_blank" href="' + i.content + '"><i class="material-icons">launch</i>Open File</a>';
    }
    html = true;
  }
  if (html) {
    return <span className='c-p' onClick={() => preview(i.content)} dangerouslySetInnerHTML={{ __html: content }} />
  } else {
    return <><span>{content}</span></>;
  }

}

export function checktimeVisible(time, oldtime) {
  if (!oldtime) return true;
  if (time.user_id !== oldtime.user_id) return true;
  return time.createdAt.slice(0, 16) !== oldtime.createdAt.slice(0, 16);
}

export function checktimeVisibleIcon(time, oldtime) {
  if (!oldtime) return true;
  if (time.user_id !== oldtime.user_id) return true;
  return time.createdAt.slice(0, 10) !== oldtime.createdAt.slice(0, 10);
}

export function CricketPlayingType(type) {
  if (type == 'wk') return 'WK';
  if (type == 'bat') return 'BAT';
  if (type == 'all') return 'AR';
  if (type == 'bowl') return 'BOW';
  return type;
}