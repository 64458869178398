import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import App from './App';
import "./i18n";
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

const store = configureStore();

const tagManagerArgs = {
  gtmId: 'GTM-KJ2KRRZ'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('igame365App'));

root.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();