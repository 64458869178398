/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import HttpService from '../../../utils/http.service';
import { Postdata } from '../../../utils/api.service';
import Loader from '../Loader';
import Alerts from '../Alert';
import { useDispatch } from 'react-redux';

const ForgotUsernameModal = (props) => {
    const [validated, setValidated] = useState(false);
    const [validated_msg, setValidated_msg] = useState('Please enter the Email address');

    const [loader, setloader] = useState(false);
    const [email, setemail] = useState('');
    const [message, setmessage] = useState({ type: 'danger', message: false });
    const dispatch = useDispatch();

    const onForgotAction = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            setmessage({ type: 'danger', message: false });
            setloader(true);
            Postdata(HttpService.forgotUsernameUrl, { email: email }, dispatch).then((result) => {
                if (result.status === 200) {
                    setValidated(false);
                    setmessage({ type: 'success', message: result.message });
                    setemail('');
                    setTimeout(() => {
                        props.loginRedirect();
                    }, 1500);
                    setloader(false);
                } else {
                    setmessage({ type: 'danger', message: result.message });
                    setemail('');
                    setValidated_msg(result.message);
                    setloader(false);
                    setTimeout(() => {
                        setValidated(false);
                        setValidated_msg('Please enter the Email address');
                    }, 1500);
                }
            }).catch((e) => {
                setmessage({ type: 'danger', message: e.message });
                setemail('');
                setValidated_msg(e.message);
                setloader(false);
                setTimeout(() => {
                    setValidated(false);
                    setValidated_msg('Please enter the Email address');
                }, 1500);
            });
        }
        setValidated(true);
    };

    return (
        <div className="igame-modal">
            <div className='row'>
                <div className='col-md-6'>
                    <div className='leftSidebar'>
                        <img src={require('../../../assets/igame-logo.png')} alt="" />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='rightSidebar'>
                        <h3 className='text-white'>Forgot Username ? </h3>
                        <p>Please enter registered email to recover your user name.</p>

                        {message.message &&
                            <Alerts variant={message.type} className={message.type === 'danger' ? 'd-none' : ''}>
                                {message.message}
                            </Alerts>
                        }

                        <Form noValidate validated={validated} autoComplete="off" method='get' action="/" onSubmit={onForgotAction}>
                            <Form.Group controlId="Form.ControlForgot" className="custom-input">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    pattern="^[^ ]+@[^ ]+\.[a-z]{1,20}$"
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                    autoFocus
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{email.length ? 'Entered Email address is invalid' : validated_msg}.</Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                type='submit'
                                className='primary'
                                disabled={loader}
                            >
                                {loader ? <Loader fullscreen="forms" /> : 'Submit'}
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotUsernameModal;