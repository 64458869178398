/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { friendsRefresh } from '../../../redux/actions/authActions';

import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import Loader from '../Loader';
import Alerts from '../Alert';

const ProfileRequestAcceptRejectModal = (props) => {
    const [loader, setloader] = useState(false);
    const [message, setmessage] = useState('');
    const dispatch = useDispatch();

    const setNotificationStatus = (action, id) => {
        setmessage('');
        setloader(true);
        Postdata(HttpService.socialFriendRequest, { user_id: id, action: action }, dispatch)
            .then((res) => {
                setmessage(res.message);
                setTimeout(() => {
                    dispatch(friendsRefresh());
                    props.hidePopup();
                }, 1000);
            }).catch((e) => {
                setmessage(e.message);
                setloader(false);
            });
    }

    return (
        <div className="igame-modal">
            <div className='rightSidebar goodLuck text-center'>
                <h5 className='text-white'>Request Received</h5>
                <div className='profile-modal-view'>
                    <img src={props.profileData.profile_pic} />
                    <p className='sentRequest'><b>{props.profileData.username}</b> sent you friend request</p>
                    <div className='profile-sec'>
                        <button
                            onClick={() => setNotificationStatus('accept', props.profileData._id)}
                            className='btn primary'
                            disabled={loader}
                        >
                            {loader ? <Loader fullscreen="forms" /> : 'Accept'}
                        </button>
                        <button
                            onClick={() => setNotificationStatus('reject', props.profileData._id)}
                            className='btn secondary'
                            disabled={loader}
                        >
                            {loader ? <Loader fullscreen="forms" /> : 'Reject'}
                        </button>
                    </div>
                    {message &&
                        <Alerts variant="success">
                            {message}
                        </Alerts>
                    }
                </div>
            </div>
        </div >
    )
}

export default ProfileRequestAcceptRejectModal;