/* eslint-disable react/prop-types */
import { Modal } from 'react-bootstrap';

function ModalPopup(props) {
  return (
    <Modal
      centered
      // backdrop="static"
      // onShow={() => document.getElementsByTagName('html')[0].classList.add('modal-html')}
      // onExit={() => document.getElementsByTagName('html')[0].classList.remove('modal-html')}
      keyboard={false}
      {...props}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  );
}


export default ModalPopup;
