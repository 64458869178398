/* eslint-disable no-unused-vars */
import axios from 'axios';
import { openLoginPopup } from '../redux/actions/authActions';

axios.interceptors.request.use(function (config) {
  if (window.localStorage.getItem('authToken')) {
    config.headers.Authorization = `Bearer ${window.localStorage.getItem('authToken')}`;
  }
  config.headers.Language = window.localStorage.getItem('i18nextLng');
  return config;
});

//Post Data
export function Postdata(url, payload, dispatch, header) {
  if (!header) header = {};
  if (!("headers" in header)) {
    header = Object.assign({
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }, header);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, header)
      .then((response) => {
        if (response.data.message === "Invalid token, Please try again.") {
          dispatch && dispatch(openLoginPopup());
          response.data.message = 'Login is required.'
        }
        resolve(response.data);
      })
      .catch((e) => {
        e.message = (e.message == 'Network Error') ? 'Please check your internet connection and try again...' : e.message;
        reject(e);
      });
  });
}

//Get
export function Get(url, dispatch) {
  let header = {};
  if (!("headers" in header)) {
    header = Object.assign({
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }, header);
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, header)
      .then((response) => {
        resolve(response.data);
        if (response.data.message === "Invalid token, Please try again.") {
          dispatch && dispatch(openLoginPopup());
        }
      })
      .catch((e) => {
        e.message = (e.message == 'Network Error') ? 'Please check your internet connection and try again...' : e.message;
        reject(e);
      });
  });
}