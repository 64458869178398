/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { friendsRefresh } from '../../../redux/actions/authActions';
import { createCall, closeCall } from '../../../redux/actions/callActions';

import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import Loader from '../Loader';
import Alerts from '../Alert';
import { Link } from 'react-router-dom';
import { isCall } from '../../../redux/selectors/callSelector';

const ProfileViewModal = (props) => {
  const [loader1, setloader1] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [message, setmessage] = useState('');
  const [chatId, setchatId] = useState({});
  const dispatch = useDispatch();
  const Call = useSelector(isCall);

  const addFriendRequest = (id) => {
    setmessage('');
    setloader1(true);
    Postdata(HttpService.socialFriendRequest, { user_id: id, action: 'unfriend' }, dispatch)
      .then((res) => {
        setmessage(res.message);
        setTimeout(() => {
          dispatch(friendsRefresh());
          props.hidePopup();
        }, 1000);
      }).catch((e) => {
        setmessage(e.message);
        setloader1(false);
      });
  }

  const addBlockRequest = (id) => {
    setmessage('');
    setloader2(true);
    Postdata(HttpService.socialBlockRequest, { user_id: id }, dispatch)
      .then((res) => {
        setmessage(res.message);
        setTimeout(() => {
          dispatch(friendsRefresh());
          props.hidePopup();
        }, 1000);
      }).catch((e) => {
        setmessage(e.message);
        setloader2(false);
      })
  }

  useEffect(() => {
    setchatId(props.profileData);
  }, []);

  const startCall = (type) => {
    if (Call) dispatch(closeCall());

    setTimeout(() => {
      dispatch(createCall({
        ...props.profileData,
        type: type,
        group_id: chatId.group_id,
        socket_id: chatId.socket_id,
        user_id: chatId.user_id
      }));
    }, 200);
    props.hidePopup();
  }

  return (
    <div className="igame-modal">
      <div className='rightSidebar goodLuck text-center'>
        <h5 className='text-white text-cap-'>{props.profileData.username}</h5>
        <div className='profile-modal-view'>
          <span className='p-r'>
            <img src={props.profileData.profile_pic} />
            <span className={`socket con-${props.profileData.socket_id ? true : false}`} />
          </span>
          <p>{props.profileData.username}</p>


          <div className='profile-sec'>
            {chatId.group_id ?
              <>
                <Link onClick={() => props.hidePopup()} to={`/chat/${chatId.group_id}/${props.profileData._id}`}><span className="material-icons">question_answer</span></Link>
              </>
              :
              <>
                <span className="material-icons" style={{ opacity: 0.2 }}>question_answer</span>
              </>
            }
            {chatId.socket_id ?
              <>
                <span onClick={() => startCall('audio')} className="material-icons">call</span>
                <span onClick={() => startCall('video')} className="material-icons">videocam</span>
              </>
              :
              <>
                <span className="material-icons" style={{ opacity: 0.2 }}>call</span>
                <span className="material-icons" style={{ opacity: 0.2 }}>videocam</span>
              </>
            }
          </div>

          <div className='profile-sec bgchange'>
            <button onClick={() => addFriendRequest(props.profileData._id)} className='btn secondary'>{loader1 ? <Loader fullscreen="forms" /> : 'Unfriend'}</button>
            <button onClick={() => addBlockRequest(props.profileData._id)} className='btn primary'>{loader2 ? <Loader fullscreen="forms" /> : 'Block'}</button>
          </div>
          {message &&
            <Alerts variant="success">
              {message}
            </Alerts>
          }
        </div>
      </div>
    </div>
  )
}

export default ProfileViewModal;