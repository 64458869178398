import * as types from "../constants/constants";

let initialState = {
  isto: false,
  to: {},
  time: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATECALL:
      return {
        ...state,
        isto: true,
        to: action.data,
        time: false
      };
    case types.CLOSECALL:
      return {
        ...state,
        isto: false,
        to: {},
        time: Math.random(),
      };
    case types.IMCOMINGCALL:
      return {
        ...state,
        time: false,
      };
    default:
      return state;
  }
};