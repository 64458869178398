/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { updateSocket, updateUserOnline, updateNotification, chatlistUpdate, addPlayersData } from '../redux/actions/homeActions';
import { verifyToken } from '../redux/actions/authActions'
import { closeCall } from '../redux/actions/callActions';
import { isAuthenticated, isData } from '../redux/selectors/authSelector';
import { isCall, isReceiver } from '../redux/selectors/callSelector';
import AgoraRTC, { AgoraVideoPlayer } from 'agora-rtc-react';
import HttpService from '../utils/http.service';
import { Postdata } from '../utils/api.service';
import Timer from './common/Timer';
import *  as AgoraLog from "agora-rtc-sdk-ng";
import { isChatForceReload } from '../redux/selectors/homeSelector';
AgoraLog.setLogLevel(4);
var rtc = {};

const Socket = () => {
  const [joined, setJoined] = useState({ status: false, type: false });
  const leaveRef = useRef("");
  const ProfileData = useSelector(isData);
  const Authenticated = useSelector(isAuthenticated);
  const chatforcereload = useSelector(isChatForceReload);
  const [socket, setSocket] = useState(null);
  const [isconnected, setisconnected] = useState(false);
  const dispatch = useDispatch();

  const Call = useSelector(isCall);
  const CallDetails = useSelector(isReceiver);
  const [fullscreen, setfullscreen] = useState(false);
  const [fromuser, setfromuser] = useState(false);
  const [callinfo, setcallinfo] = useState({});
  const [users, setUsers] = useState([]);
  const [_rtc, set_rtc] = useState({});
  const ringSound = document.getElementById("myAudioRing");
  const ringSoundFrom = document.getElementById("myAudioRingfrom");
  const [trackState, setTrackState] = useState({ video: true, audio: true, loader: false });

  useEffect(() => {
    if (Authenticated) {
      const newSocket = io(process.env.REACT_APP_SOCKET_URL);
      setSocket(newSocket);
      return () => newSocket.close();
    }
  }, [Authenticated]);

  useEffect(() => {
    // const uid = (Math.floor(Math.random() * 1000) + 1000)
    let agora_uid = ProfileData?.user?.user_id
    if (fromuser) {
      Postdata(HttpService.AgoraToken, {
        channelName: CallDetails.group_id,
        // uid: CallDetails.user_id.slice(-8)
        uid: agora_uid.slice(-8)
      }).then((e) => {
        if (e.status === 200) {
          // ProfileData?.user?._id?.toString().slice(-8)
          handleSubmit(CallDetails.type, CallDetails.group_id, e.data.token, agora_uid);
        }
      });
    }
  }, [fromuser]);

  useEffect(() => {
    if (chatforcereload && socket) {
      socket.emit("get_chat_user_list", { _id: ProfileData?.user?._id, parent_user_id: ProfileData?.user?.parent_user_id?._id });
    }
  }, [chatforcereload, socket]);

  useEffect(() => {
    // const uid = (Math.floor(Math.random() * 1000) + 1000)
    let agora_uid = ProfileData?.user?.user_id
    if (callinfo.type) {
      Postdata(HttpService.AgoraToken, {
        channelName: callinfo.to.group_id,
        // uid: callinfo.from.user_id.slice(-8)
        uid: agora_uid.slice(-8)
      }).then((e) => {
        if (e.status === 200) {
          handleSubmit(callinfo.type, callinfo.to.group_id, e.data.token, agora_uid);
        }
      });
    }
  }, [callinfo]);

  useEffect(() => {
    if (socket) {
      socket.onAny((event, args) => {
        console.warn(`Event ${event} >`, args);
      });
      socket.on('connect', function () {
        socket.emit("set_socket_id", { socket_id: socket.id, user_id: ProfileData?.user?._id });
        socket.emit("get_chat_user_list", { _id: ProfileData?.user?._id, parent_user_id: ProfileData?.user?.parent_user_id?._id });
        setisconnected(socket.connected);
      });
      socket.on('disconnect', function () {
        handleLeave();
        setisconnected(socket.connected);
      });
      socket.on('receive_call', function (msg) {
        if (socket.id !== msg._id) {
          setcallinfo(msg);
        } else {
          setfromuser(Math.random());
        }
      });
      socket.on('exit_call', function () {
        handleLeave();
      });
      socket.on('user_online', function (data) {
        dispatch(updateUserOnline(data));
      });
      socket.on('winning-users-data', function (data) {
        dispatch(addPlayersData(data))
      });
      socket.on('payment_success', function (data) {
        dispatch(verifyToken());
      });
      socket.on('update_chat_user_list', function () {
        socket.emit("get_chat_user_list", { _id: ProfileData?.user?._id, parent_user_id: ProfileData?.user?.parent_user_id?._id });
      });
      socket.on('get_chat_user_list', function (data) {
        dispatch(chatlistUpdate(data));
      });
      socket.on('notification', function (data) {
        dispatch(updateNotification(data));
        dispatch(updateUserOnline(data));
        dispatch(verifyToken());
      });
      socket.on('incoming_call', function (data) {
        setJoined({ ...joined, status: true, type: 'calling', calltype: data.type, user: data.from, _id: data._id, to_id: data.to_id, data: data, created_call_type: data.created_call_type, groupInfo: data.groupInfo });
      });
    }
  }, [socket]);

  useEffect(() => {
    dispatch(updateSocket(isconnected));
  }, [isconnected]);

  useEffect(() => {
    removeLocalStream();

    if (Call && socket) {
      console.log("================create_call==============")
      console.log({
        _id: socket.id,
        to_id: CallDetails.socket_id,
        from: ProfileData.user,
        to: CallDetails,
        type: CallDetails.type,
        call_type: CallDetails.call_type
      })
      console.log("===================================")

      socket.emit("create_call", {
        _id: socket.id,
        to_id: CallDetails.socket_id,
        from: ProfileData.user,
        to: CallDetails,
        type: CallDetails.type,
        call_type: CallDetails.call_type
      });
      setJoined({ ...joined, status: true, type: 'calling' });
    }
  }, [Call]);

  useEffect(() => {
    if (ringSound) ringSound.currentTime = 0;
    if (ringSoundFrom) ringSoundFrom.currentTime = 0;
    if (joined.type === 'calling') {
      if (!Call) {
        ringSound?.play();
      } else {
        ringSoundFrom?.play();
      }
      const intervalId = setTimeout(() => {
        console.log("============exit_call=================")
        console.log({
          _id: socket.id,
          to_id: Call ? CallDetails.socket_id : joined._id,
          to: Call ? CallDetails : joined,
          from: ProfileData.user,
          groupDetails: CallDetails,
          call_exiting_user_id: ProfileData.user._id,
          created_call_type: CallDetails.call_type,
          groupInfo: joined.groupInfo
        })
        console.log("====================================")

        socket.emit("exit_call", {
          _id: socket.id,
          to_id: Call ? CallDetails.socket_id : joined._id,
          to: Call ? CallDetails : joined,
          from: ProfileData.user,
          groupDetails: CallDetails,
          call_exiting_user_id: ProfileData.user._id,
          created_call_type: CallDetails.call_type,
          groupInfo: joined.groupInfo

        });
        ringSound?.pause();
        ringSoundFrom?.pause();
      }, 30000);
      return () => clearTimeout(intervalId);
    } else {
      ringSound?.pause();
      ringSoundFrom?.pause();
    }
  }, [joined]);

  useEffect(() => {
    if (_rtc.client) {
      _rtc.client.on("user-published", async (user, mediaType) => {
        console.log("user-published");
        await _rtc.client.subscribe(user, mediaType);

        if (mediaType === "video" || mediaType === "all") {
          setUsers((prevUsers) => {
            return [...prevUsers.filter((User) => User.uid !== user.uid), user];
            // return [...prevUsers, user];
          });
        }

        if (mediaType === "audio" || mediaType === "all") {
          const remoteAudioTrack = user.audioTrack;
          remoteAudioTrack.play();
        }
      });

      _rtc.client.on("user-unpublished", (user, type) => {
        console.log("user-unpublished");
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      _rtc.client.on("user-joined", (user, type) => {
        // _rtc.client.subscribe(user);
        // setUsers((prevUsers) => {
        //   return [...prevUsers.filter((User) => User.uid !== user.uid), user];
        //   // return [...prevUsers, user];
        // });
        console.log("user-joined");
      });

      _rtc.client.on("user-left", (user, type) => {
        console.log("user-left");
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });
    }
  }, [_rtc]);

  async function handleSubmit(callinfotype, channel, token, user_id) {
    setJoined({ ...joined, status: true, type: callinfotype });
    setTrackState({ video: true, audio: true, loader: false });
    if (callinfotype === 'audio') setTrackState({ video: false, audio: true, loader: false });

    try {
      rtc.callinfotype = callinfotype;
      rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
      set_rtc({ ...rtc, d: Math.random() });

      await rtc.client.join(
        ProfileData?.setting?.agora_app_id,
        channel,
        token,
        parseInt(user_id.slice(-8))
        // parseInt(user_id),
      );

      // rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

      if (callinfotype === 'audio') mute('force');
      // rtc.localVideoTrack.play("local-stream");

      if (callinfotype === 'audio') {
        rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        set_rtc({ ...rtc, d: Math.random() });
      }
      if (callinfotype === 'video') {
        rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

        rtc.localVideoTrack.play("local-stream");
        set_rtc({ ...rtc, d: Math.random() });
      }

      rtc.client.on("user-published", async (user, mediaType) => {
        set_rtc({ ...rtc, d: Math.random() });
      });

      rtc.client.on("user-unpublished", (user, type) => {
        set_rtc({ ...rtc, d: Math.random() });
      });

      // rtc.client.on("user-joined", (user) => {
      //   set_rtc({ ...rtc, d: Math.random() });
      //   console.log("New user added 2");
      // });

      rtc.client.on("user-left", (user) => {
        set_rtc({ ...rtc, d: Math.random() });
      });

      if (callinfotype === 'audio') {
        await rtc.client.publish([rtc.localAudioTrack]);
        set_rtc({ ...rtc, d: Math.random() });
      }
      if (callinfotype === 'video') {
        await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
        set_rtc({ ...rtc, d: Math.random() });
      }

      if (callinfotype === 'audio') mute('force');
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

      set_rtc({ ...rtc, d: Math.random() });
    } catch (error) {
      console.error(error);
    }
  }

  async function leaveCall(token) {
    setJoined({ ...joined, status: false });
    console.log("==============exit_call======================")
    console.log({
      _id: socket.id,
      to_id: token,
      to: Call ? CallDetails : joined.user,
      from: ProfileData.user,
      groupDetails: CallDetails,
      call_exiting_user_id: ProfileData.user._id,
      created_call_type: CallDetails.call_type,
      groupInfo: joined.groupInfo
    })
    console.log("==========================================")
    socket.emit("exit_call", {
      _id: socket.id,
      to_id: token,
      to: Call ? CallDetails : joined.user,
      from: ProfileData.user,
      groupDetails: CallDetails,
      call_exiting_user_id: ProfileData.user._id,
      created_call_type: CallDetails.call_type,
      groupInfo: joined.groupInfo
    });
    handleLeave();
  }

  async function acceptCall() {
    setTrackState({ video: true, audio: true, loader: false });
    console.log("=========ACCEPT CALL==============")
    console.log({
      ...joined.data,
      call_accepting_user_id: ProfileData.user._id
    })
    console.log("====================================")
    socket.emit("accept_call", {
      ...joined.data,
      call_accepting_user_id: ProfileData.user._id
    });
    setTimeout(() => {
      set_rtc({ ...rtc, hide: 'audio', d: Math.random() });
    }, 2000);
  }

  async function handleLeave() {
    setJoined({ ...joined, status: false });
    setfullscreen(false);
    setTrackState({ video: true, audio: true, loader: false });
    dispatch(closeCall());
    removeLocalStream();
  }

  async function removeLocalStream() {
    setTrackState({ video: true, audio: true, loader: false });
    try {
      console.log("remove local stream");
      const localContainer = document.getElementById("local-stream");
      rtc?.localAudioTrack?.close();
      rtc?.localVideoTrack?.close();

      if (localContainer) localContainer.textContent = "";

      rtc?.client?.remoteUsers?.forEach((user) => {
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });

      await rtc.client?.leave();
      rtc.client?.removeAllListeners();
    } catch (err) {
      console.error(err);
    }
  }

  const mute = async (type) => {
    setTrackState((ps) => {
      return { ...ps, loader: true };
    });
    if (type === "force") {
      await rtc?.localVideoTrack?.setEnabled(false);
      setTrackState((ps) => {
        return { ...ps, video: false, loader: false };
      });
    } else if (type === "audio") {
      await rtc?.localAudioTrack?.setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio, loader: false };
      });
    } else if (type === "video") {
      await rtc?.localVideoTrack?.setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video, loader: false };
      });
    }
  }

  return (
    <>
      {Authenticated ?
        <div className={`call_action ${joined.status ? '_run' : ''} ${fullscreen ? 'fullscreen' : ''}`}>
          <i className="material-icons fullscreen-toggle" onClick={() => setfullscreen(!fullscreen)}>{fullscreen ? 'fullscreen_exit' : 'fullscreen'}</i>
          <i style={{ display: 'none' }} className="material-icons fullscreen-toggle fullscreen-toggle-mobile" onClick={() => setfullscreen(!fullscreen)}>{fullscreen ? 'call' : 'expand_more'}</i>
          <audio id="myAudioRing" loop controls>
            <source src={require('../assets/web/to_ring.mp3')} type="audio/mpeg" />
          </audio>
          <audio id="myAudioRingfrom" loop controls>
            <source src={require('../assets/web/from_ring.mp3')} type="audio/mpeg" />
          </audio>
          {/* <div className={`joined-${joined.type === 'video' ? '' : joined.type} ${((!_rtc?.localVideoTrack?._isClosed && _rtc?.localVideoTrack?._enabled) || (users?.[0]?.videoTrack?._isClosed !== undefined && !users?.[0]?.videoTrack?._isClosed)) ? 'joined-video' : ''}`}> */}
          <div className={`joined-${joined.type === 'video' ? '' : joined.type} ${((!_rtc?.localVideoTrack?._isClosed && _rtc?.localVideoTrack?._enabled) || (users?.[0]?.videoTrack?._isClosed !== undefined)) ? 'joined-video' : ''}`}>
            {/* <div id="local-stream" className="stream local-stream"></div> */}
            
            <div
              id="remote-stream"
              className="remote-stream"
            >
              <div id="local-stream" className='stream inner-stream'></div>
              {users.length > 0 && joined.type === "video" &&
                users.map((user) => {

                  if (user.videoTrack) {
                    return (
                      <AgoraVideoPlayer className='stream inner-stream' videoTrack={user.videoTrack} key={user.uid} />
                    );
                  } else return null;
                })}
            </div>
          </div>
          {joined.status ?
            joined.type === 'calling' ?
              <>
                <b>
                  <div>
                    <img src={Call ? CallDetails.profile_pic : joined?.user?.profile_pic} />
                  </div>
                  <span>{Call ? CallDetails.type : joined.calltype}</span> call {Call ? `to ${CallDetails.username}` : `by ${joined?.user?.username}`}
                </b>
                {Call ?
                  <div className='call-btns'>
                    <span ref={leaveRef} onClick={() => leaveCall(Call ? CallDetails.socket_id : callinfo._id)} className="material-icons"> call_end </span>
                  </div>
                  :
                  <div className='call-btns'>
                    <span ref={leaveRef} onClick={() => acceptCall()} className="material-icons call"> call </span>
                    <span ref={leaveRef} onClick={() => leaveCall(Call ? CallDetails.socket_id : joined._id)} className="material-icons"> call_end </span>
                  </div>
                }
              </>
              :
              <>
                <i>{_rtc?.localAudioTrack ? <Timer /> : 'Wait...'}</i>
                <b><span>{joined.type}</span> call {Call ? `to ${CallDetails.username}` : `by ${callinfo?.from?.username}`}</b>

                <div className='call-btns'>
                  <span className={`material-icons mic ${trackState.audio ? "on" : ""}`}
                    onClick={() => !trackState.loader ? mute("audio") : {}}>
                    {trackState.audio ? "mic" : "mic_off"}
                  </span>

                  {joined.type === 'video' ?
                    <span className={`material-icons camera ${trackState.video ? "on" : ""}`}
                      onClick={() => !trackState.loader ? mute("video") : {}}>
                      {trackState.video ? "videocam" : "videocam_off"}
                    </span>
                    : ''}

                  <span ref={leaveRef} onClick={() => leaveCall(Call ? CallDetails.socket_id : callinfo._id)} className="material-icons"> call_end </span>
                </div>
              </>
            : null
          }
        </div>
        : null
      }
    </>
  );
}

export { Socket }

