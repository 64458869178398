/* eslint-disable no-unused-vars */
import { Container, Navbar, Form, FormControl, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated, isData, isBecomeAgent, isLoginRequired } from "../../redux/selectors/authSelector";
import { loginClear, verifyToken, friendsRefresh } from "../../redux/actions/authActions";

import ModalPopup from './Modal';
import LoginModal from './Modal/Login';
import ForgotPasswordModal from './Modal/ForgotPassword';
import ForgotUsernameModal from './Modal/ForgotUsername';
import RegisterModal from './Modal/Register';
import GoodLuckModal from './Modal/GoodLuck';
import SearchModal from './Modal/Search';
import SearchModal2 from '../gameSearch/Search.component';
import ProfileRequestModal from './Modal/ProfileRequest';
import ProfileRequestCancelModal from './Modal/ProfileRequestCancel';
import ProfileRequestAcceptRejectModal from './Modal/ProfileRequestAcceptReject';
import ProfileViewModal from './Modal/ProfileView';
import RequestSuccessModal from './Modal/RequestSuccess';
import BecomeAgentModal from './Modal/BecomeAgent';
import BecomeAgentSuccessModal from './Modal/BecomeAgentSuccess';
import ContactUsModal from './Modal/ContactUs';

import MainMenu from './Menu/MainMenu';

import iconArrow from '../../assets/arrow.svg';
import iconLogout from '../../assets/logout.svg';
import iconNewsfeed from '../../assets/newsfeed.svg'
import iconChat from '../../assets/chat.svg'
import iconProfile from '../../assets/girl-user.png'
// import iconMenu from '../../assets/nav.svg'
import iconMenu from '../../assets/menuNew.svg'

// import iconLogo from '../../assets/igame-circle.png'
import iconLogo from '../../assets/home-mobile.png'

import { Link } from 'react-router-dom';
import Loader from './Loader';
import { Postdata } from '../../utils/api.service';
import HttpService from '../../utils/http.service';
import { ConvertTime, numberWithCommas } from '../Helper';
import { isSocket, isNotification } from '../../redux/selectors/homeSelector';
import OnlineUsers from './OnlineUsers';
import { updateNotification } from '../../redux/actions/homeActions';

const Header = () => {
  const Authenticated = useSelector(isAuthenticated);
  const ProfileData = useSelector(isData);
  const becomeAgent = useSelector(isBecomeAgent);
  const autoLogin = useSelector(isLoginRequired);
  const mySocket = useSelector(isSocket);
  const notifications = useSelector(isNotification);
  const [notificationicon, setnotificationicon] = useState(0);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);
  const notification_icons = ['rewards_add', 'coins_withdrawal', 'coins_add'];
  const notification_redirect = {
    'rewards_add': '/rewards',
    'coins_withdrawal': '/coins/transactions',
    'comment_add': '/feed',
    'coins_add': '/coins/transactions',
  };

  const expand = false;
  const [modalLogin, setmodalLogin] = useState(false);
  const [modalContactUs, setmodalContactUs] = useState(false);
  const [modalRegister, setmodalRegister] = useState(false);
  const [modalForgotPassword, setmodalForgotPassword] = useState(false);
  const [modalForgotUsername, setmodalForgotUsername] = useState(false);
  const [modalGoodLuck, setmodalGoodLuck] = useState(false);
  const [modalSearch, setmodalSearch] = useState(false);
  const [modalFriendRequest, setmodalFriendRequest] = useState(false);
  const [modalFriendRequestSuccess, setmodalFriendRequestSuccess] = useState(false);
  const [modalFriendView, setmodalFriendView] = useState(false);
  const [modalBecomeAgent, setmodalBecomeAgent] = useState(false);
  const [modalAgentSuccess, setmodalAgentSuccess] = useState(false);
  const [modalFriendRequestCancel, setmodalFriendRequestCancel] = useState(false);
  const [modalFriendRequestAcceptReject, setmodalFriendRequestAcceptReject] = useState(false);

  const [profileData, setprofileData] = useState({});
  const [nofitications, setnofitications] = useState(false);
  const [page, setPage] = useState(1);
  const [recordslength, setrecordsLength] = useState(0);

  const checkMenuOpen = () => {
    try {
      if (!document.getElementById('bottom-menu').classList.contains('collapsed')) {
        document.getElementById('bottom-menu').click();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const forgotModal = () => {
    setmodalLogin(false);
    setmodalRegister(false);
    setmodalForgotPassword(true);
    setmodalGoodLuck(false);
    setmodalContactUs(false)
    setmodalForgotUsername(false);
  }

  const forgotUsernameModal = () => {
    setmodalLogin(false);
    setmodalRegister(false);
    setmodalForgotPassword(false);
    setmodalGoodLuck(false);
    setmodalContactUs(false)
    setmodalForgotUsername(true);
  }


  const closeModal = () => {
    setmodalLogin(false);
    setmodalRegister(false);
    setmodalForgotPassword(false);
    setmodalGoodLuck(false);
    setmodalContactUs(false)
    setmodalForgotUsername(false);
  }

  const loginModal = () => {
    setnotificationicon(0);
    setmodalLogin(true);
    setmodalRegister(false);
    setmodalForgotPassword(false);
    setmodalForgotUsername(false);
    setmodalGoodLuck(false);
    setmodalContactUs(false)
  }

  const registerModal = () => {
    setnotificationicon(0);
    setmodalLogin(false);
    setmodalRegister(true);
    setmodalForgotPassword(false);
    setmodalGoodLuck(false);
    setmodalContactUs(false)
    setmodalForgotUsername(false);
  }

  const contactUsModal = () => {
    setnotificationicon(0);
    setmodalLogin(false);
    setmodalRegister(false);
    setmodalForgotPassword(false);
    setmodalGoodLuck(false);
    setmodalContactUs(true)
    setmodalForgotUsername(false);
  }

  const goodluckModal = () => {
    setmodalLogin(false);
    setmodalRegister(false);
    setmodalForgotPassword(false);
    setmodalGoodLuck(true);
    setmodalContactUs(false)
    setmodalForgotUsername(false);
  }

  const closeSuccessModal = () => {
    setmodalFriendRequestSuccess(false);
    // setmodalSearch(true);
  }

  const LogoutCallApi = () => {
    dispatch(loginClear());
    return false;
  };

  const showFriendPopup = (data) => {
    setprofileData(data);
    setmodalSearch(false);
    setmodalFriendRequestCancel(false);
    setmodalFriendRequest(true);
  };

  const showFriendPopupCancel = (data) => {
    setprofileData(data);
    setmodalSearch(false);
    setmodalFriendRequest(false);
    setmodalFriendRequestCancel(true);
  };

  const showPopupAcceptReject = (data) => {
    setprofileData(data);
    setmodalSearch(false);
    setmodalFriendRequest(false);
    setmodalFriendRequestCancel(false);
    setmodalFriendRequestAcceptReject(true);
  };

  const showViewPopup = (data) => {
    setprofileData(data);
    setmodalSearch(false);
    setmodalFriendView(true);
  };

  //Fn to auto close search modal on gameLaunch page
  const closePopupOnGameLaunch = ()=>{
    setmodalSearch(false)
  }

  const getNotifications = (type) => {
    setnotificationicon(0);
    dispatch(updateNotification({ count: 0 }));
    setLoader(true);
    if (!type) setPage(1);
    if (Authenticated) {
      if (page === 1) {
        setnofitications(false);
        Postdata(HttpService.notificationStatus, { is_viewed: 1 }, dispatch);
      }
      Postdata(HttpService.notificationList, { page: !type ? 1 : page }, dispatch).then((res) => {
        if (res.status === 200) {
          if (!type || page === 1) {
            setnofitications(res.data.records);
          } else {
            setnofitications([...nofitications, ...res.data.records]);
          }
          setrecordsLength(res.data.total);
        }
        setLoader(false);
      }).catch(e => {
        setLoader(false);
      });
    }
  }

  useEffect(() => {
    (ProfileData.notification) ? setnotificationicon(ProfileData.notification) : setnotificationicon(0);
    if (notifications.count !== false) setnotificationicon(notifications.count);
  }, [notifications, ProfileData]);

  useEffect(() => {
    if (autoLogin) {
      loginModal();
      setmodalSearch(false);
      setmodalFriendRequest(false);
      setmodalFriendRequestCancel(false);
      setmodalFriendRequestAcceptReject(false);
    }
  }, [autoLogin]);

  useEffect(() => {
    if (page !== 1) {
      getNotifications(true);
    }
  }, [page]);

  const redirectNotification = (path) => {
    navigate(path);
    document.body.click();
  }

  const setNotificationStatus = (action, id, notificationId) => {
    Postdata(HttpService.socialFriendRequest, { user_id: id, action: action }, dispatch)
      .then(() => {
        setnofitications(false);
        getNotifications();
        dispatch(friendsRefresh());
      }).catch(() => {
        setnofitications(false);
        getNotifications();
      });
  }

  useEffect(() => {
    if (!Authenticated && localStorage.getItem('authToken')) {
      dispatch(verifyToken());
    }
    if (!localStorage.getItem('authToken') && window.location.hash) registerModal();
  }, [Authenticated]);

  useEffect(() => {
    if (becomeAgent) {
      setmodalBecomeAgent(true);
    }
  }, [becomeAgent]);

  useEffect(() => {
    if (state) {
      setmodalLogin(true);
      navigate(location.pathname, {});
    }
  }, [state]);

  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset + window.innerHeight;
      if (currentPosition > document.getElementsByTagName("footer")[0].offsetTop) {
        document.getElementById('main-menu').style.height = '100vh px';
        // document.getElementById('main-menu').style.height = 'calc(100vh - ' + ((currentPosition - document.getElementsByTagName("footer")[0].offsetTop) + 58) + 'px)';
      } else {
        document.getElementById('main-menu').style.height = '100vh';
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);


  const segment = (location.pathname).split('/');
  const fsegment = segment[1].toString();
  // console.log("pathname");
  // console.log(location.pathname);
  // console.log("segment");
  // console.log(fsegment);
  // console.log(typeof fsegment)


  return (
    <>
      <OnlineUsers />
      <header className={Authenticated ? '' : 'login-idle'}>
        <Navbar key={expand} expand={expand} className="mb-3" style={{flexWrap : 'noWrap'}}>
          <Container>
            <div>
              <Navbar.Toggle id="top-menu" aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Brand as={Link} to="/">
                <img src={require('../../assets/igame-logo-web.png')} />
                <img className='mobile' src={require('../../assets/iGame@3x-mb.png')} />
              </Navbar.Brand>
            </div>
            <div className="d-flex">
              {Authenticated &&
                <div className='coins'>
                  <div className='coin-list'>
                    <p>
                      <img src={require('../../assets/Coin.png')} />
                      {numberWithCommas(ProfileData.user.usable_coin_balance)}
                    </p>
                    <p>
                      <img className='award' src={require('../../assets/awards.png')} />
                      {numberWithCommas(ProfileData.user.withdrawable_coin_balance)}
                    </p>
                  </div>
                  <div>
                    <Link to={'/coins'}>
                      <button>
                        <span className="material-icons">add</span>
                      </button>
                    </Link>
                  </div>
                </div>
              }

              {!Authenticated &&
                <div className='mobile-view-header'>
                  <div>
                    <button onClick={() => setmodalLogin(true)}>Login</button>
                  </div>
                  <div className='filled'>
                    <button onClick={() => setmodalRegister(true)}>Register</button>
                  </div>
                </div>

              }

              <Form className={(Authenticated && ProfileData?.user.allow_social_media) ? 'd-flex' : 'd-none'} onClick={() => { Authenticated ? setmodalSearch(true) : setmodalLogin(true) }} >
                <span className="material-icons">search</span>
                <FormControl
                  type="text"
                  placeholder="Search Game"
                  className="me-2"
                  readOnly
                />
              </Form>

              <div className={`nav-buttons ${!Authenticated && 'm-0'}`}>
                {(() => {
                  if (!Authenticated && window.localStorage.getItem('authToken')) {
                    return (
                      <Loader fullscreen="forms" />
                    )
                  } else if (Authenticated) {
                    return (
                      <div className='loggedin'>
                        <OverlayTrigger
                          // trigger="hover"
                          rootClose
                          trigger="click"
                          placement="bottom"
                          overlay={
                            <Popover
                              className='Header-Top'
                              body
                            >
                              {nofitications &&
                                (nofitications.map((i) => {
                                  return <div
                                    key={i._id}
                                    className={`notification${notification_icons.includes(i.type) ? ' not-profile' : ''}${Object.keys(notification_redirect).includes(i.type) ? ' c-p' : ''}`}
                                    onClick={() =>
                                      Object.keys(notification_redirect).includes(i.type) ?
                                        redirectNotification(notification_redirect[i.type])
                                        : ''
                                    }
                                  >
                                    <div className='information'>
                                      <img src={i?.from_user?.profile_pic} className={i.type} />
                                      <div>
                                        <p>
                                          {/* {(i.type === 'friendship_add_friend' || i.type === 'friendship_accept') && <b>{i.from_user.username}</b>} */}
                                          {i?.from_user?.username && <b>{i?.from_user?.username}</b>}
                                          {i.text}
                                        </p>
                                        <i>{ConvertTime(i.createdAt)}</i>
                                        {i.type === 'friendship_add_friend' ?
                                          <>
                                            <button disabled={i.is_viewed_} onClick={() => setNotificationStatus('accept', i.from_user._id, i._id)} className='btn primary'>Accept</button>
                                            <button disabled={i.is_viewed_} onClick={() => setNotificationStatus('reject', i.from_user._id, i._id)} className='btn invert'>Reject</button>
                                          </>
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                }))
                              }
                              {nofitications.length === 0 && <p className='nofound'>No notification found...</p>}
                              {(!loader && nofitications.length && (nofitications.length < recordslength)) ? <p className='loadmore' onClick={() => setPage(page + 1)}>Load more...</p> : ''}
                              {(!nofitications || loader) && <Loader />}
                            </Popover>
                          }
                        >
                          <span
                            data-count={notificationicon}
                            onClick={() => getNotifications()}
                            className={`material-icons ${notificationicon ? 'notify' : ''}`}
                          >
                            notifications
                          </span>
                        </OverlayTrigger>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-button">
                            <b className='profile-top-img p-r'>
                              <img src={ProfileData.user.profile_pic} alt="" />
                              {/* <span className={`socket con-${mySocket}`} /> */}
                            </b>
                            {ProfileData.user.username}
                            <img src={iconArrow} className="user-dropdown" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/profile"><img src={ProfileData.user.profile_pic} className="profile-pic" alt="" /> Profile</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={LogoutCallApi}><img src={iconLogout} /> Logout</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )
                  } else {
                    return (
                      <>
                        <div>
                          <button onClick={() => setmodalLogin(true)}>Login</button>
                        </div>
                        <div className='filled'>
                          <button onClick={() => setmodalRegister(true)}>Register</button>
                        </div>
                      </>
                    )
                  }
                })()}
              </div>
            </div>

            <Navbar.Collapse
              id="offcanvasNavbar-expand"
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <div className='nav-links' id="main-menu">
                <MainMenu
                  LoginPopup={() => setmodalLogin(true)}
                  RegisterPopup={() => setmodalRegister(true)}
                />
                <div className="logo-mobile">
                  <img src={require('../../assets/igame-logo.png')} />
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
          <div className='mobile-menu'>
            <div className={'menu-list ' + fsegment}>
              {(() => {
                if (!Authenticated) {
                  return (
                    <>
                      <Link to={'/feed'}><img src={iconNewsfeed} /> <b>News Feed</b></Link>
                      <Link to={'/chat'}><img src={iconChat} /> <b>Chat</b></Link>
                      <Link onClick={() => checkMenuOpen()} to='/' className='menu-logo'>
                        <div className="pulse1"></div>
                        <div className="pulse2"></div>
                        <img src={iconLogo} />
                        <b>Games</b>
                      </Link>
                      <a href='#' onClick={() => setmodalLogin(true)}><img className='profile-m-img' src={iconProfile} /> <b>Profile</b></a>
                      <Navbar.Toggle id="bottom-menu" aria-controls={`offcanvasNavbar-expand-${expand}`}><img src={iconMenu} /> <b>Menu</b></Navbar.Toggle>
                    </>
                  )
                } else if (Authenticated && ProfileData.user.allow_social_media) {

                  return (
                    <>
                      <Link to={'/feed'}><img src={iconNewsfeed} /> <b>News Feed</b></Link>
                      <Link to={'/chat'}><img src={iconChat} /> <b>Chat</b></Link>
                      <Link onClick={() => checkMenuOpen()} to='/' className='menu-logo'>
                        <div className="pulse1"></div>
                        <div className="pulse2"></div>
                        <img src={iconLogo} />
                        <b>Games</b>
                      </Link>
                      <Link onClick={() => checkMenuOpen()} to='/profile'>
                        <span className="p-r">
                          <img src={ProfileData.user.profile_pic} className='profile-m-img' alt="" />
                        </span>
                        <b>{ProfileData.user.username}</b>
                      </Link>
                      <Navbar.Toggle id="bottom-menu" aria-controls={`offcanvasNavbar-expand-${expand}`}><img src={iconMenu} /> <b>Menu</b></Navbar.Toggle>
                    </>
                  )
                } else if (Authenticated && !(ProfileData.user.allow_social_media)) {
                  return (
                    <>
                      <Link onClick={() => checkMenuOpen()} to='/profile'>
                        <span className="p-r">
                          <img src={ProfileData.user.profile_pic} className='profile-m-img' alt="" />
                        </span>
                        <b>{ProfileData.user.username}</b>
                      </Link>
                      <Link onClick={() => checkMenuOpen()} to='/' className='menu-logo'>
                        <div className="pulse1"></div>
                        <div className="pulse2"></div>
                        <img src={iconLogo} />
                        <b>Games</b>
                      </Link>
                      <Navbar.Toggle id="bottom-menu" aria-controls={`offcanvasNavbar-expand-${expand}`}><img src={iconMenu} /> <b>Menu</b></Navbar.Toggle>
                    </>
                  )
                }
              }
              )()}
            </div>
          </div>

        </Navbar>
      </header>

      <ModalPopup
        show={modalLogin}
        size="lg"
        onHide={() => setmodalLogin(false)}
      >
        <LoginModal
          forgotModal={forgotModal}
          forgotUsernameModal={forgotUsernameModal}
          registerModal={registerModal}
          closeModal={closeModal}
          contactUsModal={contactUsModal}
        />
      </ModalPopup>

      <ModalPopup
        show={modalForgotUsername}
        size="lg"
        onHide={() => setmodalForgotUsername(false)}
      >
        <ForgotUsernameModal
          forgotModal={forgotModal}
          forgotUsernameModal={forgotUsernameModal}
          registerModal={registerModal}
          closeModal={closeModal}
          contactUsModal={contactUsModal}
          loginRedirect={loginModal}
        />
      </ModalPopup>

      <ModalPopup
        show={modalContactUs}
        size="lg"
        onHide={() => setmodalContactUs(false)}
      >
        <ContactUsModal
          closeModal={closeModal}
          loginRedirect={loginModal}
        />
      </ModalPopup>

      <ModalPopup
        show={modalForgotPassword}
        size="lg"
        onHide={() => setmodalForgotPassword(false)}
      >
        <ForgotPasswordModal
          loginRedirect={loginModal}
          closeModal={closeModal}
        />
      </ModalPopup>

      <ModalPopup
        show={modalRegister}
        size="lg"
        onHide={() => setmodalRegister(false)}
      >
        <RegisterModal
          loginModal={loginModal}
          goodluckModal={goodluckModal}
          contactUsModal={contactUsModal}
        />
      </ModalPopup>

      <ModalPopup
        show={modalGoodLuck}
        size="sm"
        onHide={() => setmodalGoodLuck(false)}
      >
        <GoodLuckModal
          closeModal={closeModal}
        />
      </ModalPopup>

    {/* Search.component */}
      <ModalPopup
        show={modalSearch}
        size="lg"
        className="search-modal"
        centered={false}
        onHide={() => setmodalSearch(false)}
      >
        <SearchModal2
          showFriendPopup={showFriendPopup}
          showFriendPopupCancel={showFriendPopupCancel}
          showViewPopup={showViewPopup}
          showPopupAcceptReject={showPopupAcceptReject}
          closePopupOnGameLaunch={closePopupOnGameLaunch}
        />
      </ModalPopup>

      {/* <ModalPopup
        show={modalSearch}
        size="lg"
        className="search-modal"
        centered={false}
        onHide={() => setmodalSearch(false)}
      >
        <SearchModal
          showFriendPopup={showFriendPopup}
          showFriendPopupCancel={showFriendPopupCancel}
          showViewPopup={showViewPopup}
          showPopupAcceptReject={showPopupAcceptReject}
        />
      </ModalPopup> */}


      <ModalPopup
        show={modalFriendRequest}
        size="sm"
        // onHide={() => { setmodalFriendRequest(false); setmodalSearch(true); }}
        onHide={() => { setmodalFriendRequest(false); }}
      >
        <ProfileRequestModal
          hidePopup={() => { setmodalFriendRequest(false); setmodalFriendRequestSuccess(true); }}
          // hideErrorPopup={() => { setmodalFriendRequest(false); setmodalSearch(true); }}
          hideErrorPopup={() => { setmodalFriendRequest(false); }}
          profileData={profileData}
        />
      </ModalPopup>

      <ModalPopup
        show={modalFriendRequestCancel}
        size="sm"
        // onHide={() => { setmodalFriendRequestCancel(false); setmodalSearch(true); }}
        onHide={() => { setmodalFriendRequestCancel(false); }}
      >
        <ProfileRequestCancelModal
          // hidePopup={() => { setmodalFriendRequestCancel(false); setmodalSearch(true); }}
          hidePopup={() => { setmodalFriendRequestCancel(false); }}
          profileData={profileData}
        />
      </ModalPopup>

      <ModalPopup
        show={modalFriendRequestAcceptReject}
        size="sm"
        // onHide={() => { setmodalFriendRequestAcceptReject(false); setmodalSearch(true); }}
        onHide={() => { setmodalFriendRequestAcceptReject(false); }}
      >
        <ProfileRequestAcceptRejectModal
          // hidePopup={() => { setmodalFriendRequestAcceptReject(false); setmodalSearch(true); }}
          hidePopup={() => { setmodalFriendRequestAcceptReject(false); }}
          profileData={profileData}
        />
      </ModalPopup>

      <ModalPopup
        show={modalFriendRequestSuccess}
        size="sm"
        // onHide={() => { setmodalFriendRequestSuccess(false); setmodalSearch(true); }}
        onHide={() => { setmodalFriendRequestSuccess(false); }}
      >
        <RequestSuccessModal
          profileData={profileData}
          onClose={closeSuccessModal}
        />
      </ModalPopup>


      <ModalPopup
        show={modalFriendView}
        size="sm"
        // onHide={() => { setmodalFriendView(false); setmodalSearch(true); }}
        onHide={() => { setmodalFriendView(false); }}
      >
        <ProfileViewModal
          // hidePopup={() => { setmodalFriendView(false); setmodalSearch(true); }}
          hidePopup={() => { setmodalFriendView(false); }}
          profileData={profileData}
        />
      </ModalPopup>


      <ModalPopup
        show={modalBecomeAgent}
        size="lg"
        onHide={() => setmodalBecomeAgent(false)}
      >
        <BecomeAgentModal
          isAgenttrue={() => { setmodalBecomeAgent(false); setmodalAgentSuccess(true); }}
        />
      </ModalPopup>

      <ModalPopup
        show={modalAgentSuccess}
        size="lg"
        onHide={() => setmodalAgentSuccess(false)}
      >
        <BecomeAgentSuccessModal
          hidePopup={() => { setmodalAgentSuccess(false); }}
        />
      </ModalPopup>
    </>
  )
}

export default Header;