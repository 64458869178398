import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pagetop } from "../Helper";

function ScrollToTop() {
  const { pathname } = useLocation();

  const scrollTop = () => {
    pagetop();
  }

  useEffect(() => {
    scrollTop();

    try {
      const timer = setTimeout(() => {
        document.body.click();
      }, 10);
      if (window.innerWidth < 992) {
        if (!document.getElementById('bottom-menu').classList.contains('collapsed')) {
          document.getElementById('bottom-menu').click();
        }
      }
      return () => clearTimeout(timer);
    } catch (error) {
      console.log(error);
    }
  }, [pathname]);

  return null;
}

export default ScrollToTop;