import React, { Suspense } from "react";
import lazyWithSync from "./lazyWithSync";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

const Homepage = lazyWithSync(() => import("../components/pages/Homepage"));
const LiveCasino = lazyWithSync(() => import("../components/pages/liveCasino/LiveCasino.component"));
const GameLaunch = lazyWithSync(()=> import("../components/pages/gameLaunch/GameLaunch.component"));
const Leaderboard = lazyWithSync(() => import("../components/pages/Leaderboard"));

const Profile = lazyWithSync(() => import("../components/pages/Profile"));
const ProfilePassword = lazyWithSync(() => import("../components/pages/Profile_Password"));
const ProfileBankDetails = lazyWithSync(() => import("../components/pages/Profile_Bank_Details"));
const ProfileFriends = lazyWithSync(() => import("../components/pages/Profile_Friends"));
const KYC = lazyWithSync(() => import("../components/pages/KYC"))
// const ProfileFriendsLeaderboard = lazyWithSync(() => import("../components/pages/Profile_Friends_Leaderboard"));
const Coins = lazyWithSync(() => import("../components/pages/Coins"));
const Transactions = lazyWithSync(() => import("../components/pages/Transactions"));
const CoinsWithdraw = lazyWithSync(() => import("../components/pages/CoinsWithdraw"));
const VIP = lazyWithSync(() => import("../components/pages/VIP"));
const Refer_Earn = lazyWithSync(() => import("../components/pages/Refer_Earn"));
const Rewards = lazyWithSync(() => import("../components/pages/Rewards"));

const Fantasy_Game = lazyWithSync(() => import("../components/pages/cricket/Fantasy_Game"));
const Fantasy_Match = lazyWithSync(() => import("../components/pages/cricket/Fantasy_Match"));
const Fantasy_View = lazyWithSync(() => import("../components/pages/cricket/Fantasy_View"));
const Fantasy_Select = lazyWithSync(() => import("../components/pages/cricket/Fantasy_Select"));
const Fantasy_Captain = lazyWithSync(() => import("../components/pages/cricket/Fantasy_Captain"));
const Fantasy_View_Team = lazyWithSync(() => import("../components/pages/cricket/Fantasy_View_Team"));
const Fantasy_View_Preview = lazyWithSync(() => import("../components/pages/cricket/Fantasy_View_Preview"));

const Spin_Wheel = lazyWithSync(() => import("../components/pages/Spin_Wheel"))

const GameHistory = lazyWithSync(() => import("../components/pages/Game_History"));
const Page = lazyWithSync(() => import("../components/pages/Page"));
const Chat = lazyWithSync(() => import("../components/pages/Chat"));
const Game = lazyWithSync(() => import("../components/pages/Game"));
const VideoCallPage = lazyWithSync(() => import("../components/pages/Video_Call"));
const SocketChat = lazyWithSync(() => import("../components/pages/Socket_chat"));
const Feed = lazyWithSync(() => import("../components/pages/Feed"));
const Error404 = lazyWithSync(() => import("../components/common/404"));

const ProviderGames = lazyWithSync(()=> import("../components/pages/providerGames/ProviderGames.component"));
const ProviderGamesSlots = lazyWithSync(()=> import("../components/pages/slotsGames/ProviderGamesSlots/ProviderGamesSlots.component"));
const AllProvidersCasino = lazyWithSync(()=> import("../components/pages/providerListAll/ProviderListAll.component"))
const AllProvidersSlots = lazyWithSync(()=> import("../components/pages/slotsGames/ProviderListAllSlots/ProviderListAllSlots.component"))

import PrivateRoute from "./PrivateRoute";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import ScrollToTop from "../components/common/ScrollToTop";
import Loader from "../components/common/Loader";
import ShowHeaderOnCondition from '../components/showHeaderOnCondition/showHeaderOnCondition.component'
import SlotsGames from "../components/pages/slotsGames/SlotsGames.component";

function DefaultRoutes() {
  return (
    <section className="page">
      <Router>
        <ShowHeaderOnCondition>
          <Header />        
        </ShowHeaderOnCondition>
        <ScrollToTop />
        <Suspense fallback={<Loader fullscreen="loader_fullscreen" />}>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/:id" element={<Homepage />} />
            
            {/* Live casino private route */}
            <Route exact path="/livecasino/:version" element={<PrivateRoute Component={LiveCasino} />} />
            <Route exat path="/livecasino/provider/:providerid/:providername/:version" element={<PrivateRoute Component={ProviderGames}/>}/>
            <Route exact path="/livecasino/allproviders/:version" element={<PrivateRoute Component={AllProvidersCasino} />}/>
            <Route exact path='/gamelaunch/:version/:id' element={<PrivateRoute size="md-size" Component={GameLaunch}/>} />
            
            {/* Slots Games routues  */}
            {/* Game type - slots or livecasino */}
            <Route exact path='/:gametype/:version' element={<PrivateRoute Component={SlotsGames} />} />
            <Route exact path="/allproviders/:version/:gametype" element={<PrivateRoute Component={AllProvidersSlots} />}/>
            <Route exat path="/provider/:providerid/:providername/:version/:gametype" element={<PrivateRoute Component={ProviderGamesSlots}/>}/>
            
            <Route exact path="/call" element={<VideoCallPage />} />
            <Route exact path="/page/:pagename" element={<Page />} />

            <Route exact path="/global-leaderboard" element={<Leaderboard type="global" />} />
            {/* <Route exact path="/friends-leaderboard" element={<PrivateRoute Component={ProfileFriendsLeaderboard} />} /> */}
            <Route exact path="/friends-leaderboard" element={<PrivateRoute Component={Leaderboard} />} />

            <Route exact path="/chat" element={<PrivateRoute size="md-size" Component={SocketChat} />} />
            <Route exact path="/chat/:room/:userid" element={<PrivateRoute size="md-size" Component={SocketChat} />} />
            <Route exact path="/game/:gamename" element={<PrivateRoute size="xl-size" Component={Game} />} />
            <Route exact path="/game/:gamename/:gametype" element={<PrivateRoute size="xl-size" Component={Game} />} />
            {/* <Route exact path="/game/:gamename/:gametype" element={<Game />} /> */}

            <Route exact path="/profile" element={<PrivateRoute Component={Profile} />} />
            <Route exact path="/profile/game-played" element={<PrivateRoute Component={GameHistory} />} />
            <Route exact path="/profile/bank-details" element={<PrivateRoute Component={ProfileBankDetails} />} />
            <Route exact path="/profile/password" element={<PrivateRoute Component={ProfilePassword} />} />
            <Route exact path="/profile/friends" element={<PrivateRoute Component={ProfileFriends} />} />
            <Route exact path="/profile/kyc" element={<PrivateRoute Component={KYC} />} />

            <Route exact path="/coins" element={<PrivateRoute Component={Coins} />} />
            <Route exact path="/coins/transactions" element={<PrivateRoute Component={Transactions} />} />
            <Route exact path="/coins/withdraw" element={<PrivateRoute Component={CoinsWithdraw} />} />
            <Route exact path="/vip-club" element={<PrivateRoute Component={VIP} />} />
            <Route exact path="/refer-earn" element={<PrivateRoute Component={Refer_Earn} />} />
            <Route exact path="/rewards" element={<PrivateRoute Component={Rewards} />} />
            <Route exact path="/game-history" element={<PrivateRoute Component={GameHistory} />} />

            <Route exact path="/socket" element={<PrivateRoute Component={Chat} />} />
            <Route exact path="/feed" element={<PrivateRoute Component={Feed} />} />

            <Route exact path="/fantasy" element={<PrivateRoute size="fantasy" Component={Fantasy_Game} />} />
            <Route exact path="/fantasy/match/:id" element={<PrivateRoute size="fantasy" Component={Fantasy_Match} />} />
            <Route exact path="/fantasy/contest/:id" element={<PrivateRoute size="fantasy" Component={Fantasy_View} />} />
            <Route exact path="/fantasy/select/:id" element={<PrivateRoute size="fantasy" Component={Fantasy_Select} />} />
            <Route exact path="/fantasy/captain/:id" element={<PrivateRoute size="fantasy" Component={Fantasy_Captain} />} />
            <Route exact path="/fantasy/team/:id" element={<PrivateRoute size="fantasy" Component={Fantasy_View_Team} />} />
            <Route exact path="/fantasy/preview/:id" element={<PrivateRoute size="fantasy" Component={Fantasy_View_Preview} />} />

            <Route exact path="/spin-wheel" element={<PrivateRoute Component={Spin_Wheel} />} />

            <Route path="*" element={<Error404 />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </section>
  );
}

export default DefaultRoutes;
