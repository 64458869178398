import * as types from "../constants/constants";

export const createCall = (data) => {
  return { type: types.CREATECALL, data };
};

export const closeCall = () => {
  return { type: types.CLOSECALL };
};

export const incomingCall = () => {
  return { type: types.IMCOMINGCALL };
};