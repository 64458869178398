/*************************************************************
 * Slots Games Page - Event bet API intergration 
 * Data fetched from back end Server and displyed here.
 * Data sent to GameCategory.component.jsx
 * Axios , scss
 * URL - /livecasino
 ************************************************************/
import React,{useState, useEffect,useRef} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {motion} from 'framer-motion'
// import { Spinner } from 'react-bootstrap';
import GameCategory from '../../gameCategory/GameCategory.component';
import ProvidersList from './ProviderListSlots/ProviderListSlots.component'
import  HttpService from '../../../utils/http.service'
import Title from '../../common/Title'
import LoadingAnimation from '../../common/LoadingAnimation/LoadingAnimation.component'

import './SlotsGames.styles.scss';




const SlotsGames = () => {

  //useState
  const [gameData, setGameData] = useState([]); //State to store All Game data
  const [usersFavourites, setUsersFavourites] = useState([]);//State to store the Favorite Games
  const [gameIdFromChild, setGameIdFromChild] = useState(null);
  const [loader, setloader] = useState(true); // loading animation

  //useParams
  const {version,gametype} = useParams(); // game type refers to slots or casino games under event bet provider

  
  
  //Scroll to position
  const childRefs = useRef([]); //Ref for child component

  //Scroll Nav
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeCategory, setActiveCategory] = useState(0);
  const scrollContainer = useRef(null);
  const startDragX = useRef(null);
  const startScrollLeft = useRef(null);
  //End scroll nav code


  //Axios Fetch
  //the fetch url changes with respect to the version passed from the url props . i.e digitain or eventbet
  // const fetchURL = (version === 'v1') ? HttpService.Live_Casino_game_fetch_URL :
  //                (version === 'games') ? HttpService.EventBet_slots_game_fetch_URL : null;

  const fetchURL = (gametype === 'livecasino' && version === 'v1') ? HttpService.Live_Casino_game_fetch_URL :
                 (gametype === 'livecasino' && version === 'games') ? HttpService.EventBet_casino_game_fetch_URL :
                 (gametype === 'slots' && version === 'games') ? HttpService.EventBet_slots_game_fetch_URL :
                 null;

  
  //Todo :Move fetch call to centrealized file - api.service with try catch. Use status code 
  
  const fetchGameData = async()=>{
    try{
      const response = await axios.get(fetchURL)
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      //assign the gameData to setGameData
      setGameData(response.data.data);
      setloader(false);

      //Check and assign the Favorites gameData to setUserFavoutites
      const newData = response.data.data
      // Find the index of the array with name "Favourites"
      // console.log('newData :>> ', newData);
      const findFavouritesIndex = newData.findIndex(item => item.name === 'Favourites');
      // Check if "Favourites" array exists and has the correct categoryId (8888)
      if (findFavouritesIndex !== -1 && newData[findFavouritesIndex].id === 8888) {
        setUsersFavourites(newData[findFavouritesIndex].gamesInfo);
        // console.log('usersFavourites :>> ', usersFavourites);
      } else {
        setUsersFavourites([])
      }

      // console.log('response live :>> ', response);
      //console.log('response live2 :>> ', newData[findFavouritesIndex].gamesInfo);

    }
    catch(error){      
      console.error('An error occurred:', error.message);
      if (error.response) {
        console.error('Response status code:', error.response.status);
      }
    }
  }//End of Axios Fetch Call


  // Use Effect
  useEffect(()=>{

      // Disable the default scroll restoration behavior
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
    }

    // Scroll to the top of the page when the component is loaded
    window.scrollTo(0, 0);
  

    fetchGameData()

    // Re-enable scroll restoration on cleanup
    return () => {
      if ('scrollRestoration' in window.history) {
          window.history.scrollRestoration = 'auto';
      }
  };
    
  },[])

  /********************************************
   * Favourites Functionality
   * ******************************************/
   // Function to find game details based on gameId
    const findGameDetails = (favGameId) => {
      let gameDetails = null;
      for (const category of gameData) {
        for (const gameInfo of category.gamesInfo) {
          if (gameInfo.gameId === favGameId) {
            gameDetails = gameInfo;
            break;
          }
        }
        if (gameDetails) {
          break;
        }
      }
      return gameDetails;
    };


  const addToFavorites = (gameId) => {
    // Create a deep copy of the state object to update the state
    const updatedGameData = JSON.parse(JSON.stringify(gameData));

    // Find the index of the "Favourites" category
    const favoritesIndex = updatedGameData.findIndex(category => category.name === "Favourites");

    if (favoritesIndex !== -1) {
      // Check if the gameId already exists in the "Favorites" category
      const gamesInfo = updatedGameData[favoritesIndex].gamesInfo;
      const gameIndex = gamesInfo.findIndex(game => game.gameId === gameId);

      if (gameIndex !== -1) {
        // If the gameId exists, remove the game data from "gamesInfo" array
        const newGamesInfo = gamesInfo.filter(game => game.gameId !== gameId);
        updatedGameData[favoritesIndex].gamesInfo = newGamesInfo;
        
        // console.log(`Game with ID ${gameId} removed from Favorites.`);
        // Update the state with the modified data to trigger re-render
         setGameData(updatedGameData);
         setUsersFavourites(updatedGameData[favoritesIndex].gamesInfo);
      } else {
        // If gameId does not exist, find the game details based on gameId
        const gameDetails = findGameDetails(gameId);

        if (gameDetails) {
          // If gameDetails is found, append it to "gamesInfo" array in "Favourites" category
          updatedGameData[favoritesIndex].gamesInfo.push(gameDetails);
          //console.log(`Game with ID ${gameId} added to Favorites.`);

          // Update the state with the modified data to trigger re-render
         setGameData(updatedGameData);
         setUsersFavourites(updatedGameData[favoritesIndex].gamesInfo);

        } else {
          console.log(`Game with ID ${gameId} not found.`);
        }
      }

      // Update the state with the modified data
      setGameData(updatedGameData);
    }
  };


 // Callback function - Fav Game Func- Fnc from child component to receive gameId
  const handleGameIdFromChild = (gameId) => {
    setGameIdFromChild({id:gameId});
  };

  //Use Effect for Fav Game Functionality
useEffect(() => {
    if (gameIdFromChild !== null) {
      addToFavorites(gameIdFromChild.id);
    }
  }, [gameIdFromChild]);


  /********************************************
   * Category nav scroll Functionality
   * ******************************************/
  // code calculates the target scroll position for the child component by subtracting the height 
  //of the navbar from the offsetTop of the target child component. 
  // The window.scrollTo function is then used to scroll to the calculated position, resulting in the child component(GameCategory) appearing after the fixed navbar section.
  const handleMenuItemClick = (index) => {
    if (childRefs.current[index]) {
      const navbarHeight = document.querySelector('.category-nav').offsetHeight + 75;
      const targetPosition = childRefs.current[index].offsetTop - navbarHeight;
      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    }
  };

  //Scroll Navbar Left/right btn click
  const handleScroll = (scrollOffset) => {
    const container = scrollContainer.current;
    const newPosition = scrollPosition + scrollOffset;

    if (newPosition >= 0 && newPosition <= container.scrollWidth - container.clientWidth) {
      container.scrollLeft = newPosition;
      setScrollPosition(newPosition);
    }
  };

  // set activeCategory state with index
  const handleClickActiveCategory = (index) => {
    setActiveCategory(index);
    setScrollPosition(index * 120);
  };

  //  Mouse drag - scroll navbar
  const handleMouseDown = (e) => {
    startDragX.current = e.clientX;
    startScrollLeft.current = scrollContainer.current.scrollLeft;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  
  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const dragDelta = e.clientX - startDragX.current;
    scrollContainer.current.scrollLeft = startScrollLeft.current - dragDelta;
  };


  return (
    <>
    {loader ? 
      <LoadingAnimation/>
      : 
    <>
      <Title name="Slots Games" />  
      <motion.div className='slots-games'
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }} 
          transition={{delay:0.2, duration: 0.6, type:'tween'}}       
      >
        <div className="product">
          <header className="category-nav">

            {/* Left btn */}
            <div className="scroll-button_left" onClick={() => handleScroll(-80)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </div>

            <div className="category-nav-bar" ref={scrollContainer}>
              { gameData?
                  gameData.map ((category,index) => {
                      if(category.gamesInfo.length === 0){
                      return null;
                      } else {
                      return(
                        <>
                        <button className={`category-nav-item ${activeCategory === index ? 'active' : ''}`} 
                          key={category.id} 
                          onClick={()=>{
                            handleMenuItemClick(index)
                            handleClickActiveCategory(index)
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseUp={handleMouseUp}
                          >
                          {category.displayName} 
                        </button> 
                        </>
                      )
                    }    
                  
                  })
                : ''
              }
            </div>  

            {/* Right Btn */}
            <div className="scroll-button_right" onClick={() => handleScroll(80)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>

          </header>

            <div className="lobby">
            {/* Displays categories with respective games */}
              <div className="lobby-categories">
                <div className="game-category-wrapper">

                {/* Map fn is broken into two parts - first 3 Category is displayed */}
                  {gameData.slice(0,3).map( (category,index) => {
                    
                    if(category.gamesInfo.length === 0){
                      return null;
                    } else {
                      
                      return (
                        <GameCategory 
                        key={category.id} 
                        game={category} 
                        usersFavourites={usersFavourites}
                        handleGameIdFromChild={handleGameIdFromChild}
                        version={version}
                        ref={(ref)=>(childRefs.current[index]=ref)}
                        />
                      )
                      
                    }
                    })
                  }
                  {/* Displays the provider list in a scrollable list */}
                  <div className="game-category-providers">
                    <ProvidersList version={version} gametype={gametype}/>
                  </div>

                  {/* Map fn is broken into two parts - data after first 3 Category is displayed */}
                  {gameData.slice(3).map( (category,index) => {
                    
                    const actualIndex = index + 3; // Adjust the index to start from 3

                    if(category.gamesInfo.length === 0){
                      return null;
                    } else {
                      
                      return (
                        <GameCategory 
                        key={category.id} 
                        game={category} 
                        usersFavourites={usersFavourites}
                        handleGameIdFromChild={handleGameIdFromChild}
                        version={version}
                        ref={(ref)=>(childRefs.current[actualIndex]=ref)}
                        />
                      )
                      
                    }
                    })
                  }

                </div>
              </div>
            </div>

          </div>
      </motion.div>
      </>
      }
    </>
  )
}

export default SlotsGames