/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { Button } from 'react-bootstrap';

import imgParty from '../../../assets/party.png';
import { useEffect } from 'react';

const GoodLuckModal = (props) => {

  useEffect(() => {
    setTimeout(() => {
      props.closeModal();
    }, 2000);
  }, [])

  return (
    <div className="igame-modal">
      <div className='row'>
        <div className='col-md-12'>
          <div className='rightSidebar goodLuck text-center'>
            <h5 className='text-white'>Good Luck</h5>
            <img src={imgParty} className="party" />
            <p>Ready to rock ultimate games?</p>
            <Button onClick={props.closeModal} type='button' className='primary' >Let's Go</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoodLuckModal;