import { lazy } from 'react';

const lazyWithSync = (componentImport) => lazy(async () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(
      'force-refreshed'
    ) || 'false'
  );

  try {
    const component = await componentImport();
    window.localStorage.setItem(
      'force-refreshed',
      'false'
    );

    return component;
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      window.localStorage.setItem(
        'force-refreshed',
        'true'
      );
      return window.location.reload();
    }

    throw error;
  }
});

export default lazyWithSync;