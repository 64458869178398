/* eslint-disable react/prop-types */
import { Spinner } from 'react-bootstrap';
import Title from './Title';
import loaderLogo from '../../assets/loader/Final-logo.gif'

function Loader(props) {

  return (
    <>
      {props.fullscreen == 'loader_fullscreen' && <Title name="Loading..." />}
      <div className={`Custom_loader ${props.fullscreen ? props.fullscreen : ''}`}>
        {(props.fullscreen === 'forms')
          ? <Spinner
            animation="border"
          />
          : <img className='spinner-border' src={loaderLogo} />
        }
        {/* <p>Please wait...</p> */}
      </div>
    </>
  );
}


export default Loader;