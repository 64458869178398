import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Postdata } from '../../utils/api.service'
import HttpService from "../../utils/http.service";
import Loader from "../common/Loader";


const Footer = () => {

  const [data, setData] = useState([]);
  const [loader, setloader] = useState(true);
  const [links, setLinks] = useState({
    ios: '',
    android: ''
  })
  const [linksLoading, setLinksLoading] = useState(true)

  useEffect(() => {

    Postdata(HttpService.pageView, { page: 'FOOTER' }).then((e) => {
      if (e.status === 200) {
        setData(e.data);
      }
      setloader(false);
    }).catch(() => {
      setloader(false);
    });

    setLinksLoading(true)
    Postdata(HttpService.appLinks, {}).then((res) => {
      if (res.status === 200) {
        setLinks({
          ios: res.data.link.ios[0],
          android: res.data.link.android[0]
        })
      }
      setLinksLoading(false)
    }).catch((e) => {
      console.log(e)
      setLinksLoading(false)

      // setloader(false);
    });
  }, []);

  return (
    <>
      <footer className='footer'>
        <div className='container'>
          <div className='link text-center'>
            <Link to='/page/about-us'>About Us</Link>
            <Link to='/page/terms-conditions'>Terms & Conditions</Link>
            <Link to='/page/responsible-gambling'>Responsible Gambling</Link>
            <Link to='/page/data-cookie'>Data & Cookie Policy</Link>
            <Link to='/page/privacy-policy'>Privacy Policy</Link>
            <Link to='/page/aml-kyc-policy'>AML/KYC Policy</Link>
            <Link to='/page/dispute'>Dispute Resolution</Link>
            <Link to='/page/privacy-self-exlusion'>Self-Exclusion</Link>
            <Link to='/page/fairness'>Fairness & RNG</Link>
            <Link to='/page/account-and-payouts'>Accounts, Payouts and Bonuses</Link>
          </div>
          <div className='content'>
            {loader ? <Loader /> : <div dangerouslySetInnerHTML={{ __html: data.content }} />}
            {/* Copyright ©️ 2022 iGame365 All rights reserved. BetMonsta is operated by Games & More B.V. registered under No. 149948 at, Klipstraat 13, Curaçao This website is licensed and regulated by Curaçao eGaming, license No. 1668/JAZ. */}
          </div>
          <div className='info'>
            <p className='text-end'>
              <b>10 Lakh</b> Trusted User
            </p>
            <img src={require('../../assets/user.png')} alt="" className='small-icon' />
            <img src={require('../../assets/secured.png')} alt="" />
            <img src={require('../../assets/prize.png')} alt="" className='small-icon' />
            <p>
              <b>100+ Crore</b> Monthly
            </p>
          </div>
          {linksLoading ? null :
            <div className='app-store-icons'>
              <a href={links.android?.value} >
                <img src={require('../../assets/web/store-android.png')} className='android-icon' />
              </a>
              <a href={links.ios?.value} >
                <img src={require('../../assets/web/store-ios.png')} className='ios-icon' />
              </a>
            </div>
          }
          <div className='app-store-icons'>
            <a href="https://verification.curacao-egaming.com/validateview.aspx?domain=igame365.com">
              <img src="https://verification.curacao-egaming.com/validate.ashx?domain=igame365.com" alt="" width="100" />
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;