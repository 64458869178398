/****************************************************
This Component shows the Header(i.e Navbar of the site) 
component on allpages except Game Launch page. 
*****************************************************/

import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';

const ShowHeaderOnCondition = ({children}) => {

    //location from react router dom
    let location = useLocation();

    //State
    const [showHeader,setShowHeader] = useState(false)
    //useEffect
    useEffect(()=>{

        //console.log('location :>> ', location);

        //Checks if the location has "/gameLaunch" in the URL
        const isGameLaunchPage = location.pathname.includes('/gamelaunch');
        //console.log('isGameLaunchPage :>> ', isGameLaunchPage);

        // Check if the device is a mobile device
        const isMobile = window.innerWidth <= 515; 
        if(isGameLaunchPage && isMobile){
            setShowHeader(false)
        } else {
            setShowHeader(true)
        }
    },[location])


  return (
    <div style={{   position:'sticky',
                    top:'0',
                    zIndex:'999'
                }}>
                    {showHeader && children}
    </div>
  )
}

export default ShowHeaderOnCondition