export const isHomepage = (state) => state.home.homepage;
export const isLoading = (state) => state.home.loading;
export const isSocket = (state) => state.home.socket;
export const isOnlineUser = (state) => state.home.onlineuser;
export const isNotification = (state) => state.home.notification;

export const isChatList = (state) => state.home.chat_list;
export const isChatLoading = (state) => state.home.chat_loading;
export const isChatForceReload = (state) => state.home.chat_force_reload;

export const isPlayersData = (state) => state.home.players_data