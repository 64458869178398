/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

import imgParty from '../../../assets/success.svg';
import { useEffect } from 'react';

const RequestSuccessModal = (props) => {
  
  useEffect(() => {
    setTimeout(() => {
      props.onClose();
    }, 2000);
  }, [])

  return (
    <div className="igame-modal">
      <div className='row'>
        <div className='col-md-12'>
          <div className='rightSidebar goodLuck text-center requestpopup'>
            <h5 className='text-white'>Request Sent</h5>
            <img src={imgParty} />
            <p>Your friend request has been sent to <b>{props.profileData.username}</b> Successfully</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestSuccessModal;