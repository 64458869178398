/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, loginClear } from "../../../redux/actions/authActions";
import { Form, Button } from 'react-bootstrap';
import { isError, isLoading, isAuthenticated } from "../../../redux/selectors/authSelector"

import Loader from '../Loader';
import Alerts from '../Alert';

import register1 from '../../../assets/register-1.svg';
import register2 from '../../../assets/register-2.svg';
import register3 from '../../../assets/register-3.svg';
import register4 from '../../../assets/register-4.svg';
import { Get } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';

const RegisterModal = (props) => {
  const [profile, setProfile] = useState('');
  const [avatar, setAvatar] = useState([]);
  const [validated, setValidated] = useState(false);
  const [validated_msg, setValidated_msg] = useState({
    username: 'Please enter the Username',
    referral_code: '',
    email: 'Please enter the Email address'
  });
  const [registerData, setregisterData] = useState({ username: '', email: '', referral_code: '', password: '' });
  const [eye, seteye] = useState(false);

  const errorMsg = useSelector(isError);
  const Authenticated = useSelector(isAuthenticated);
  const loader = useSelector(isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginClear());
    if (window.location.hash) setregisterData({ ...registerData, referral_code: window.location.hash.toString().slice(1) });
    Get(HttpService.SignupAvatar).then(res => {
      setAvatar(res.data);
    })
  }, []);

  useEffect(() => {
    if (Authenticated) {
      props.goodluckModal();
    }
  }, [Authenticated]);

  useEffect(() => {
    if (avatar && avatar.length) {
      setProfile(avatar[0].name);
    }
  }, [avatar]);

  const onRegisterAction = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      dispatch(register({ ...registerData, referral_code: registerData.referral_code.toString().trim(), profile: profile }));
    }
    setValidated(true);
  };

  useEffect(() => {
    if (errorMsg.toString().toLowerCase().includes('username')) {
      setValidated_msg({ ...validated_msg, username: errorMsg })
    } else if (errorMsg.toString().toLowerCase().includes('email')) {
      setValidated_msg({ ...validated_msg, email: errorMsg })
    } else if (errorMsg.toString().toLowerCase().includes('referral')) {
      setValidated_msg({ ...validated_msg, referral_code: errorMsg })
    } else if (registerData.username.length) {
      setValidated_msg({
        referral_code: '',
        username: 'Username must be between 5 to 15 characters',
        email: 'Please enter the Email address'
      })
    } else {
      setValidated_msg({
        referral_code: '',
        username: 'Please enter the Username',
        email: 'Please enter the Email address'
      });
    }
  }, [errorMsg, registerData]);

  useEffect(() => {
    if (errorMsg.toString().toLowerCase().includes('username')) {
      setregisterData({ ...registerData, username: '' });
    } else if (errorMsg.toString().toLowerCase().includes('email')) {
      setregisterData({ ...registerData, email: '' });
    } else if (errorMsg.toString().toLowerCase().includes('referral')) {
      setregisterData({ ...registerData, referral_code: ' ' });
    }
  }, [errorMsg]);

  const information = (e) => {
    return (<div className={`information ${e}`}>
      <b>We Are Glad You Are Here</b>
      <p>We are about to turn you into the virtual arena of exciting socialized games</p>

      <div className='info-icons'>
        <div><img src={register1} alt="" /> <span>100% Safe<br />Payments</span></div>
        <div><img src={register2} alt="" /> <span>Instant<br />Withdrawal</span></div>
        <div><img src={register3} alt="" /> <span>No Bots<br />Allowed</span></div>
        <div><img src={register4} alt="" /> <span>Fairplay<br />Protect</span></div>
      </div>
    </div>);
  }

  return (
    <div className="igame-modal">
      <div className='row'>
        <div className='col-md-6'>
          <div className='leftSidebar register'>
            {information('top')}
            <img src={require('../../../assets/igame-logo.png')} alt="" />
            {information('btm')}
          </div>
        </div>
        <div className='col-md-6'>
          <div className='rightSidebar'>
            <h3>Your games are waiting,</h3>
            <h4>Let's get sign up and play</h4>

            {errorMsg &&
              <Alerts variant="danger" className="d-none">
                {errorMsg}
              </Alerts>
            }

            <Form noValidate validated={validated} autoComplete="off" method='get' action="/" onSubmit={onRegisterAction}>
              <Form.Group controlId="Form.ControlInput4" className="custom-input">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  pattern="[a-zA-Z0-9]{5,15}"
                  autoComplete="new-username"
                  onInvalid={e => e.target.setCustomValidity('Username must be between 5 to 15 characters')}
                  onInput={e => e.target.setCustomValidity('')}
                  value={registerData.username}
                  onChange={(e) => setregisterData({ ...registerData, username: ((e.target.value).toString().replace(/[^a-zA-Z0-9]+/, "").trim()) })}
                  autoFocus
                  required
                />
                <Form.Control.Feedback type="invalid">{validated_msg.username}.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="Form.ControlInput5" className="custom-input">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  pattern="^[^ ]+@[^ ]+\.[a-z]{1,20}$"
                  value={registerData.email}
                  onChange={(e) => setregisterData({ ...registerData, email: e.target.value })}
                  required
                />
                <Form.Control.Feedback type="invalid">{registerData.email.length ? 'Entered Email address is invalid' : validated_msg.email}.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="Form.ControlInput6" className="custom-input">
                <Form.Label>Referral Code</Form.Label>
                <Form.Control
                  type="text"
                  // pattern=".{7}"
                  value={registerData.referral_code}
                  onChange={(e) => setregisterData({ ...registerData, referral_code: e.target.value.toString().trim() })}
                  required={Boolean(registerData.referral_code.length)}
                />
                {(registerData.referral_code.length && validated_msg.referral_code) ? <Form.Control.Feedback type="d-block invalid">{validated_msg.referral_code}.</Form.Control.Feedback> : ''}
              </Form.Group>
              <Form.Group controlId="Form.ControlInput7" className="custom-input">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={eye ? 'text' : 'password'}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,15}$"
                  value={registerData.password}
                  onChange={(e) => setregisterData({ ...registerData, password: e.target.value })}
                  autoComplete="new-password"
                  required
                />
                <b onClick={() => seteye(!eye)}>
                  {eye ?
                    <span className="material-icons">visibility_off</span>
                    :
                    <span className="material-icons">visibility</span>
                  }
                </b>
                <Form.Control.Feedback type="invalid">{registerData.password.length ? 'Password must contain a minimum of 6 characters & maximum of 15 characters which contain at least 1 number, 1 Uppercase, 1 lowercase & 1 Special character.' : 'Please enter the Password'}.</Form.Control.Feedback>
              </Form.Group>


              <Form.Group className="check-concert">
                <Form.Check
                  required
                  id='inline-18'
                  label="I confirm I am over the age of 18"
                  feedback="Please confirm that you are over 18."
                  feedbackType="invalid"
                />
              </Form.Group>

              {avatar.length ?
                <div className='avatars'>
                  {avatar.map(a => {
                    return (
                      <div
                        className={`avatar ${profile == a.name ? `active` : ``}`} key={a.name}
                        onClick={() => setProfile(a.name)}
                      >
                        <img src={a.img} />
                      </div>
                    )
                  })}
                </div>
                : ''}

              <Button type='submit' className='primary' disabled={loader}>{loader ? <Loader fullscreen="forms" /> : 'Sign Up'}</Button>
              <Button type='button' onClick={props.loginModal} className='secondary'>Sign In</Button>
              <div className='contact'>
                <a onClick={props.contactUsModal}>Contact us</a>
              </div>
              <div className='terms'>
                By continuing, you agree <a href='/page/terms-conditions' target="_blank">Terms & Conditions</a> and <a href='/page/privacy-policy' target="_blank">Privacy Policy</a>
              </div>
            </Form>
            <div className='footer p-0 m-0'>
                  <div className='app-store-icons'>
                    <a href='#' >
                      <img src={require('../../../assets/web/store-android.png')} className='android-icon' />
                    </a>
                    <a href='#'>
                      <img src={require('../../../assets/web/store-ios.png')} className='ios-icon' />
                    </a>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterModal;