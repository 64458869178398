import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isOnlineUser } from '../../redux/selectors/homeSelector'
import Alerts from './Alert';

function OnlineUsers() {
  const [message, setmessage] = useState(false);
  const online = useSelector(isOnlineUser);

  useEffect(() => {
    setmessage(online);
  }, [online])

  return (
    <>
      {message &&
        <Alerts className="onlineuser" key={message.time} variant="success" icon="info_outline">
          {message.message}
        </Alerts>
      }
    </>
  );
}


export default OnlineUsers;