/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { friendsRefresh } from '../../../redux/actions/authActions';

import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import Loader from '../Loader';
import Alerts from '../Alert';

const ProfileRequestCancelModal = (props) => {
    const [loader, setloader] = useState(false);
    const [message, setmessage] = useState('');
    const dispatch = useDispatch();

    const addFriendRequest = (id) => {
        setmessage('');
        setloader(true);
        Postdata(HttpService.socialFriendRequest, { user_id: id, action: 'cancel_request' }, dispatch)
            .then(() => {
                // setmessage(res.message);
                setTimeout(() => {
                    dispatch(friendsRefresh());
                    props.hidePopup();
                }, 1000);
            }).catch((e) => {
                setmessage(e.message);
                setloader(false);
            })
    }

    return (
        <div className="igame-modal">
            <div className='rightSidebar goodLuck text-center'>
                <h5 className='text-white'>Request Sent</h5>
                <div className='profile-modal-view'>
                    <img src={props.profileData.profile_pic} />
                    <p className='sentRequest'>Your friend request has already sent to <b>{props.profileData.username}</b></p>
                    <div className='profile-sec'>
                        <button
                            onClick={() => addFriendRequest(props.profileData._id)}
                            className='btn primary'
                            disabled={loader}
                        >
                            {loader ? <Loader fullscreen="forms" /> : 'Cancel Friend Request'}
                        </button>
                    </div>
                    {message &&
                        <Alerts variant="success">
                            {message}
                        </Alerts>
                    }
                </div>
            </div>
        </div >
    )
}

export default ProfileRequestCancelModal;