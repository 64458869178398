/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { friendsRefresh } from '../../../redux/actions/authActions';

import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import Loader from '../Loader';
import Alerts from '../Alert';

const ProfileRequestModal = (props) => {
    const [loader, setloader] = useState(false);
    const [message, setmessage] = useState({});
    const dispatch = useDispatch();

    const addFriendRequest = (id) => {
        setmessage({});
        setloader(true);
        Postdata(HttpService.socialFriendRequest, { user_id: id, action: 'add_friend' }, dispatch)
            .then((response) => {
                if (response.status === 200) {
                    setTimeout(() => {
                        dispatch(friendsRefresh());
                        props.hidePopup();
                    }, 1000);
                } else {
                    setmessage({ type: 'danger', text: response.message });
                    setTimeout(() => {
                        dispatch(friendsRefresh());
                        props.hideErrorPopup();
                    }, 1000);
                }
            }).catch((e) => {
                setmessage({ type: 'danger', text: e.message });
                setloader(false);
            })
    }

    return (
        <div className="igame-modal">
            <div className='rightSidebar goodLuck text-center'>
                <h5 className='text-white'>Add Friend</h5>
                <div className='profile-modal-view'>
                    <img src={props.profileData.profile_pic} />
                    <p>{props.profileData.username}</p>
                    <div className='profile-sec'>
                        <button
                            onClick={() => addFriendRequest(props.profileData._id)}
                            className='btn primary'
                            disabled={loader}
                        >
                            {loader ? <Loader fullscreen="forms" /> : 'Add Friend'}
                        </button>
                    </div>
                    {message.type &&
                        <Alerts variant={message.type}>
                            {message.text}
                        </Alerts>
                    }
                </div>
            </div>
        </div >
    )
}

export default ProfileRequestModal;