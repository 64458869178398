import * as types from "../constants/constants";

let initialState = {
  homepage: [],
  loading: true,
  chat_force_reload: false,
  chat_loading: true,
  chat_list: {
    admin: [],
    users: [],
  },
  onlineuser: '',
  lastupdated: new Date(),
  socket: false,
  notification: { count: false },
  players_data : {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.HOMEPAGEERROR:
      return {
        ...state,
        loading: action.data,
        homepage: [],
        lastupdated: new Date(),
      };
    case types.HOMEPAGE:
      return {
        ...state,
        homepage: action.data,
        loading: false,
        lastupdated: new Date(),
      };
    case types.SOCKET:
      return {
        ...state,
        socket: action.data,
      };
    case types.ONLINEUSERS:
      return {
        ...state,
        onlineuser: action.data,
      };
    case types.NOTIFICATIONS:
      return {
        ...state,
        notification: action.data,
      };
    case types.CHATLISTLOADING:
      return {
        ...state,
        chat_loading: true,
      };
    case types.CHATLISTUPDATE:
      return {
        ...state,
        chat_force_reload: new Date().getTime(),
      };
    case types.CHATLIST:
      return {
        ...state,
        chat_loading: false,
        chat_list: action.data,
      };
      case types.ADDPLAYERSDATA:
        return {
          ...state,
          players_data : action.data
        };
    default:
      return state;
  }
};