const slug = 'api-front'
const baseUrl = process.env.REACT_APP_API_URL + slug

const HttpService = {
  HomeUrl: baseUrl + '/home',
  getPlayersData : baseUrl + '/home/leaderboard/player_data',
  SignupAvatar: baseUrl + '/avatar',
  Leaderboard: baseUrl + '/leaderboard',
  LeaderboardFriends: baseUrl + '/leaderboard/friends',
  LeaderboardGameTypes: baseUrl + '/leaderboard/get_game_types',

  loginUrl: baseUrl + '/login',
  passwordUrl: baseUrl + '/update_password',
  logoutUrl: baseUrl + '/logout',
  registerUrl: baseUrl + '/signup',
  forgotPasswordUrl: baseUrl + '/forgot_password',
  forgotUsernameUrl: baseUrl + '/forgot_username',
  contactUsUrl: baseUrl + '/contact_us',
  becomeAgentUrl: baseUrl + '/become_an_agent',
  validateResetLinkUrl : baseUrl + '/validate_reset_link',
  resetPasswordWithLinkUrl : baseUrl + '/reset_password_with_link',

  verifyTokenUrl: baseUrl + '/verify-token',
  updateProfileUrl: baseUrl + '/user/update_profile',

  updateUserKyc : baseUrl + '/user/update_kyc',
  getUserKyc : baseUrl + '/user/get_kyc',

  userSendOtp : baseUrl + '/user/send_otp',
  userVerifyOtp : baseUrl + '/user/verify_otp',

  socialFetchUsers: baseUrl + '/social/fetch_users',
  socialFetchFriends: baseUrl + '/social/fetch_friends',
  socialFetchBlockedUsers: baseUrl + '/social/fetch_blocked_users',
  socialFriendRequest: baseUrl + '/social/change_friend_status',
  socialBlockRequest: baseUrl + '/social/change_blocked_status',

  notificationList: baseUrl + '/notification/list',
  notificationStatus: baseUrl + '/notification/change_status',

  pageView: baseUrl + '/page',
  appLinks: baseUrl + '/get_app_links',

  bankdetailsAgent: baseUrl + '/bank/agent',

  bankdetailsList: baseUrl + '/bank/list',
  bankdetailsSave: baseUrl + '/bank/store',
  bankdetailsDelete: baseUrl + '/bank/delete',

  Game_category: baseUrl + '/games/category',
  Game_list: baseUrl + '/games/list',
  Game_view: baseUrl + '/games/view',
  Game_subscribe: baseUrl + '/games/subscribe',

  Gameplay_turbo: baseUrl + '/get_turbogame_link',
  Gameplay_arrowwood: baseUrl + '/get_arrow_wood_link',
  Gameplay_evenbet: baseUrl + '/get_evenbet_link',

  Getprofile: baseUrl + '/user/get_profile',
  AgoraToken: baseUrl + '/get_agora_access_token',
  Chat_uploader: baseUrl + '/chat/uploads',
  Chat_create_group: baseUrl + '/chat/create_group',
  Chat_group_info: baseUrl + '/chat/group_info',
  Chat_group_user_list: baseUrl + '/chat/group_user_list',
  Chat_group_edit: baseUrl + '/chat/group_edit',


  Coins_add: baseUrl + '/user/add_coins',
  Coins_withdraw: baseUrl + '/user/withdrawal_request',
  Coins_history: baseUrl + '/user/coin_history',

  Game_history: baseUrl + '/user/game_history',
  Promocodes: baseUrl + '/promocodes',

  Feed_store: baseUrl + '/feed/store',
  Feed_reaction: baseUrl + '/feed/reaction',
  Feed_home: baseUrl + '/feed/home',
  Feed_delete: baseUrl + '/feed/delete',

  Comment_list: baseUrl + '/feed/comment_list',
  Comment_store: baseUrl + '/feed/comment',

  Rewards_list: baseUrl + '/rewards/list',
  Rewards_store: baseUrl + '/rewards/store',

  Cricket_matches: baseUrl + '/cricket/matches',
  Cricket_match_details: baseUrl + '/cricket/match_details',
  Cricket_match_contest: baseUrl + '/cricket/match_contest',
  Cricket_contest_details: baseUrl + '/cricket/contest_details',
  Cricket_contest_join: baseUrl + '/cricket/join_contest',
  Cricket_team: baseUrl + '/cricket/contest_team',
  Cricket_update_team: baseUrl + '/cricket/contest_update_team',
  Cricket_update_captain: baseUrl + '/cricket/contest_update_captain',
  Cricket_my_contest: baseUrl + '/cricket/my_contest',

  Cricket_my_contests: baseUrl + '/cricket/my_contests',
  Cricket_leaderboard_details: baseUrl + '/cricket/leaderboard',

  Football_my_contests: baseUrl + '/football/my_contests',
  Football_matches: baseUrl + '/football/matches',

  Spinwheel_get_list : baseUrl + '/spin_Wheel/list',
  Spinwheel_get_reward : baseUrl + '/spin_wheel/get_reward',

  Vip_list : baseUrl + '/vip_user/list',
  Vip_join_membership : baseUrl + '/vip_user/join_membership',

  Live_Casino_game_fetch_URL: baseUrl+'/live_casino/digitain/games',
  Live_Casino_game_set_fav:baseUrl+'/live_casino/digitain/favourites',
  Live_Casino_launch_URL : baseUrl +`/live_casino/digitain/game-launch-url`,
  Live_casino_provider_list : baseUrl +`/live_casino/digitain/providers`,
  Live_casino_provider_games: baseUrl + `/live_casino/digitain/fetchGamesByProvider`,
  Search_games:baseUrl+'/live_casino/digitain/search/',

  // Event bet Live casino games backend URL
  EventBet_casino_game_fetch_URL: baseUrl+'/live_casino/eventbet/games/live_dealer',
  EventBet_game_set_fav:baseUrl+'/live_casino/eventbet/favourites',
  EventBet_launch_URL : baseUrl +`/live_casino/eventbet/game-launch-url`,
  EventBet_provider_list : baseUrl +`/live_casino/eventbet/providers`,
  EventBet_provider_games: baseUrl + `/live_casino/eventbet/fetchGamesByProvider`,
  EventBet_Search_games:baseUrl+'/live_casino/eventbet/search/',

  // Event bet Slots Games backedn URL
  EventBet_slots_game_fetch_URL : baseUrl+'/live_casino/eventbet/games/slots'

};

export default HttpService;