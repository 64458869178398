import * as types from "../constants/constants";
import { Get } from "../../utils/api.service"
import HttpService from "../../utils/http.service"

export const homePage = () => {
  return (dispatch) => {
    Get(HttpService.HomeUrl).then((res) => {
      if (res.status === 200) {
        dispatch(updateHomepage(res.data));
      } else {
        dispatch(updateHomepageError(res.message));
      }
    }).catch((e) => {
      dispatch(updateHomepageError(e.message));
    });
  }
};

export const updateHomepage = (data) => {
  return { type: types.HOMEPAGE, data };
};

export const updateHomepageError = (data) => {
  return { type: types.HOMEPAGEERROR, data };
};

export const updateUserOnline = (data) => {
  return { type: types.ONLINEUSERS, data };
}

export const updateNotification = (data) => {
  return { type: types.NOTIFICATIONS, data };
}

export const updateSocket = (data) => {
  return { type: types.SOCKET, data };
}

export const getChatUserList = (data) => {
  return { type: types.CHATLISTUPDATE, data };
}

export const chatlistLoading = (data) => {
  return { type: types.CHATLISTLOADING, data };
}

export const chatlistUpdate = (data) => {
  return { type: types.CHATLIST, data };
}

export const addPlayersData = (data) => {
  return { type: types.ADDPLAYERSDATA, data };

}