/*************************************************************
 * Imported from liveCasino page. Recieves the props and 
 * maps the data here. Uses GameCard component
 * ***********************************************************/
import React,{useState,useRef,useEffect, forwardRef} from 'react'
import {motion} from 'framer-motion'
//import {useSelector,useDispatch} from 'react-redux';

//import {liveCasinoFetch} from '../../redux/features/liveCasinoGames/liveCasinoSlice';
import SingleGameCard from '../gameCard/SingleGameCard.component';
import './GameCategory.styles.scss'

const GameCategory = (props,ref) => {

  const GAME_LENGTH_DESKTOP =5;
  const GAME_LENGTH_MOBILE =20;

  /**********useState**********/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // stores the width of the scrren to show diff content for desktop and mobile versions
  const [seeAll, setSeeAll] = useState(false);
  const [width, setWidth] = useState(0); // Width for cards scroll in mobile version 
  const carousel = useRef();
  const [showMobileScrollContent, setShowMobileScrollContent] = useState(true); 
  

/**********props destructuring**********/
  const {displayName,id,gamesInfo} = props.game;
  const {usersFavourites,handleGameIdFromChild,version}= props;
  
  /**********useEffect**********/
  useEffect(()=>{
    // console.log(carousel.current.scrollWidth, carousel.current.offsetWidth)
    setWidth(carousel.current.scrollWidth-carousel.current.offsetWidth)
  },[])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    // Attach the event listener
    window.addEventListener('resize', handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

/**********Show games for desktop**********/
  // Determine the number of visible games based on screen size
  //shows only first 6 games , on click showMore- shows remaining
  // const numVisibleGames = window.innerWidth < 768 ? gamesInfo.length : GAME_LENGTH;
  const visibleGames = seeAll ? gamesInfo : gamesInfo.slice(0, GAME_LENGTH_DESKTOP);

  /**********Show games for mobile **********/
  const mobileVisibleGames = seeAll ? gamesInfo : gamesInfo.slice(0, GAME_LENGTH_MOBILE);
  
  // console.log('visibleGames :>> ', visibleGames);
  const ButtonName = seeAll ? 'See Less' : 'See More'

  /**********Methods**********/
  const handleDesktopSeeMoreBtn = () => {
    // Handle the click response 
    setSeeAll( seeAll => !seeAll)
  };
  const handleMobileSeeMoreBtn = () => {
    // Handle the click response 
    setSeeAll( seeAll => !seeAll)
    setShowMobileScrollContent(!showMobileScrollContent)
  };

  
  return (
    <>
        {windowWidth > 600 ? (
        /**********Content for Desktop**********/
        <div id={id} ref={ref} className="game_category">
          <div className="game_category_header">
            <h3 className='game_category_heading'>{displayName}</h3>
            
            {visibleGames.length >= GAME_LENGTH_DESKTOP ?
              <button onClick={handleDesktopSeeMoreBtn} className="game_category_expand" >{ButtonName}</button>
            :
              ''
            }
          </div>

          <div ref={carousel} className="carousel">
            <div className="game_category_container inner-carousel">
              {visibleGames.map( singleGame =>(
                <SingleGameCard 
                key={singleGame.gameId} 
                singleGameInfo={singleGame} 
                usersFavourites={usersFavourites}
                version={version}
                handleGameIdFromChild={handleGameIdFromChild}
                />
                ))
              }
            </div>
          </div>

        </div>
      ) : (
          /**********Content for Mobile**********/
          <div id={id} ref={ref} className="game_category">
            <div className="game_category_header">
              <h3 className='game_category_heading'>{displayName}</h3>
              
              {mobileVisibleGames.length >= 3 ?
                <button onClick={handleMobileSeeMoreBtn} className="game_category_expand" >{ButtonName}</button>
              :
                ''
              }
            </div>
            {/* showMobileScrollContent state -show scroll game content for mobile on initail load. 
            When clicked see more the scroll content is hidden and replaced with block content */}
            {showMobileScrollContent ? (
              <motion.div ref={carousel} className="carousel">
              <motion.div drag='x' dragConstraints={{right:0, left: -width}} className="game_category_container inner-carousel">
                {mobileVisibleGames.map( singleGame =>(
                  <SingleGameCard 
                  key={singleGame.gameId} 
                  singleGameInfo={singleGame} 
                  usersFavourites={usersFavourites}
                  version={version}
                  handleGameIdFromChild={handleGameIdFromChild}
                  />
                  ))
                }
              </motion.div>
            </motion.div>
            ) : (
              <div className="game_category_windowed-grid_container">
                
                  {/* <h4>test</h4> */}
                  {mobileVisibleGames.map( singleGame =>(
                    <SingleGameCard 
                    key={singleGame.gameId} 
                    singleGameInfo={singleGame} 
                    usersFavourites={usersFavourites}
                    version={version}
                    handleGameIdFromChild={handleGameIdFromChild}
                    />
                    ))
                  }
              
            </div>
            )}
            

          </div>
      )}
    </>
  )
}

export default forwardRef(GameCategory)