import React, {useState} from 'react'
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

import HttpService from '../../utils/http.service';
import {Postdata} from '../../utils/api.service'
import SearchResultCard from '../searchResultCard/searchResultCard.component'
import './Search.styles.scss';


const Search = (props) => {
  //State
  const [searchTerm, setSearchTerm] = useState(false);
  const[searchResults, setSearchResults] = useState([]);
  const[userSearchResults,setUserSerchResults]= useState([]);
  const[searchType, setSearchType]=useState('games');
  const [error, seterror] = useState('');
  const [loading, setLoading] = useState(false);
  
  const dispatch = useDispatch();

  //URL
    const searchApi = `${HttpService.Search_games}${searchType}/${searchTerm}`;
  // console.log('searchApi :>> ', searchApi);
  
   //Search Results for games
  const gameSearch = async ()=>{
  try {
    setLoading(true);
    const response = await axios.get(searchApi)
    if(response.status !== 200){
      throw new Error(`Request failed with status code ${response.status}`);
    }
    setSearchResults(response.data)
    // console.log('response.data :>> ', response.data);
  } catch (error) {
    console.log('An error occured:', error.message)
    if(error.message){
      console.error('Response status code:',error.response.status);
    }
  } finally {
    setLoading(false)
  }
}
console.log('error :>> ', error);


//Search results for Users
const userSearch = async() => {

  if (searchTerm) {
    Postdata(HttpService.socialFetchUsers, {
      search: (searchTerm ? searchTerm : ''),
    }, dispatch).then((response) => {
      if (response.status === 200) {
        setUserSerchResults(response.data.records); 
      } else {
        seterror(response.message);
      }
    }).catch((e) => {
      seterror(e.message);
    });
  } else {
    
    setUserSerchResults([]);
  }
  
}


//handle SearchType for radio button
const handleSearchTypeChange = (newSearchType) => {
  setSearchType(newSearchType);
};

//handle search function for enter key
const handleEnterKeyPress = (e) =>{
  (e.key === 'Enter' && searchType === 'games') ? gameSearch() :
  (e.key === 'Enter' && searchType === 'users') && userSearch();
}


  return (
    <div className='gameSearch_wrapper'>
      {/* Search bar with dropdown and search button */}
      <div className="gameSearch_wrapper_searchbar">

        <div className="gameSearch_wrapper_searchbar_input">
          <span className="material-icons">search</span>
          <Form.Control
            type="text"
            value={searchTerm ? searchTerm : ''}
            onChange={(e) => setSearchTerm((e.target.value).toString())}
            onKeyDown={handleEnterKeyPress}
            autoFocus
            placeholder="Find your favourite games or friends"
          />
        </div>

        <div className='gameSearch_wrapper_searchbar_buttons'>
          <div className='gameSearch_wrapper_searchbar_buttons_type'> 
            <label>
              <input
                type="radio"
                value="games"
                checked={searchType === 'games'}
                onChange={() => handleSearchTypeChange('games')}
              />
              Games
            </label>

            <label>
              <input
                type="radio"
                value="users"
                checked={searchType === 'users'}
                onChange={() => handleSearchTypeChange('users')}
              />
              Users
            </label>


          </div>
          <button onClick={searchType ==='games' ? gameSearch : userSearch}>
            Search 
          </button>
        </div>

      </div>
      
      {/* Populate search data here or  remove the heading*/}

      {loading && 
      <div className="loading_animation">
       <Spinner animation="border" />
      </div> }
     
    {/* If else */}
    {searchResults.data && searchType === 'games' ? // parent-if
      <>
        <h5>Search Results</h5> 
        <hr />
        {/* Sarch Result for Games */}
        <div className="searcResultsWrapper">
          {
            searchResults.data.map(searchData=>
              <SearchResultCard key={searchData.gameId} searchData={searchData} popupCallbacks={props} />
          ) 
          }
        </div>
      </>
        :   //parent-else 
        <>

        {userSearchResults && searchType === 'users' ? //child-if
        <> 
        {
        }
          <h5>Search Results</h5>
          <hr />
          {/* Search Result for Users */}
            <div className="searcResultsWrapper">
            {
               userSearchResults.map(searchData=>
                <SearchResultCard key={searchData.user_id} searchData={searchData} popupCallbacks={props} />
                ) 
                
            }
          </div>
        </>
        :  // child-else
          <>
            {/* Remove search results on empty input*/}
          </>
        }

        </>
      } 
        
    </div>
  )
}

export default Search