/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { loginMessage } from '../../redux/actions/authActions';
import { pagetop } from '../Helper';

function Alerts(props) {
  const [show, setshow] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    pagetop();
    const intervalId = setTimeout(() => {
      setshow(false);
      // setshow(true);
    }, 2000);
    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    if (!show) {
      dispatch(loginMessage());
    }
  }, [show]);

  return (
    <>
      <Alert
        show={show}
        onClick={() => setshow(false)}
        {...props}
      >
        {props.variant === 'danger' && <span className="material-icons">{props.icon ? props.icon : 'info_outline'}</span>}
        {props.variant === 'success' && <span className="material-icons">{props.icon ? props.icon : 'check'}</span>}
        <div>{props.children}</div>
      </Alert>
    </>
  );
}

export default Alerts;