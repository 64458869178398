/*************************************************************
 * This is a sub component inside Slots  page.
 * Fetchs and displays the provider data in a scrollable list
 * 
 * *****************************************************/
import React,{useState,useEffect,useRef} from 'react'
import {motion,AnimatePresence} from 'framer-motion'
import axios from 'axios'
import { Link } from "react-router-dom";
import {Spinner} from 'react-bootstrap'

import HttpService from '../../../../utils/http.service';
import '../../../providersList/ProviderList.styles.scss';


const ProvidersList = (props) => {


  const PROVIDER_LENGTH_DESKTOP =5;
  const PROVIDER_LENGTH_MOBILE =3;

    /**********useState**********/
    const[width,setWidth]=useState(0);
    const[providerData,setProviderData]=useState([]);
    const[filteredProviderData,setFilteredProviderData]=useState([]);
    const [seeAll, setSeeAll] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // stores the width of the screen to show diff content for desktop and mobile versions
    

    /**********props destructuring**********/
    const{version,gametype}= props

    /**********useRef**********/
    const carousel = useRef();

    /**********useEffect**********/
    useEffect(()=>{
        fetchProviderData()
    },[])

    useEffect(() => {
      // Calculate and set width when providerData is populated
      if (carousel.current && providerData.length > 0) {
        const newWidth = carousel.current.scrollWidth - carousel.current.offsetWidth;
        setWidth(newWidth);
      }
    }, [providerData]);

    
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      // Attach the event listener
      window.addEventListener('resize', handleResize);
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    /**********Axios Call - Fetch Providers Data**********/

    const providerFetchURL = (version === 'v1') ? HttpService.Live_casino_provider_list :
                              (version === 'games') ? HttpService.EventBet_provider_list : null;

    
    const fetchProviderData = async () =>{
      try{
        const response =await axios.get(providerFetchURL)
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        //assign the gameData to setGameData
        setProviderData(response.data);
        // console.log('response.data :>> ', response); // TODO:Delete
        filteredProvidersAndSetData(response.data);
        
      }
      catch(error){
        console.error('An error occurred:', error.message);
        if (error.response) {
          console.error('Response status code:', error.response.status);
        }
      }
    }


    /********** Methods for sort & arranging the providers with image **********/
    const filteredProvidersAndSetData = (responseData) => {
      const filteredData = filterProviderData(responseData);
      // console.log('filteredData :>> ', filteredData);
      setFilteredProviderData(filteredData);
    };
    /********** Sort providers with image first then rest of providers**********/
    const filterProviderData = (responseData) => {
      const filteredDataWithImage = responseData.filter(item => item.providerImgUrl !== null);
      const filteredDataWithOutImage = responseData.filter(item => item.providerImgUrl === null);
      return [...filteredDataWithImage, ...filteredDataWithOutImage];
    };


    /**********Show Providers for desktop**********/
  // Determine the number of visible providers based on screen size
  //shows only first 6 providers , on click showMore- shows remaining
  // const numVisibleGames = window.innerWidth < 768 ? gamesInfo.length : GAME_LENGTH;
  const visibleProviders = seeAll ? filteredProviderData : filteredProviderData.slice(0, PROVIDER_LENGTH_DESKTOP);
  // console.log('visibleProviders :>> ', visibleProviders);

  /**********Show games for mobile **********/
  const mobileVisibleProviders = seeAll ? filteredProviderData : filteredProviderData.slice(0, PROVIDER_LENGTH_MOBILE);
  // console.log('mobileVisibleProviders :>> ', mobileVisibleProviders);

  // const ButtonName = seeAll ? 'See Less' : 'See More'

  /**********Methods**********/
  // const handleSeeMoreBtn = () => {
  //   // Handle the click response 
  //   setSeeAll( seeAll => !seeAll)
  // };
  const handleCloseSeeMore = () =>{
    // Handle the click response when provider is clicked
    //It closes down the see more option
    setSeeAll(false)
  }

  

  return (
    <>
    {windowWidth > 600 ? (
      /**********Content for Desktop**********/
      <div className="provider-wrapper">
        {/*header*/}
        <div className="provider_header">
          <h3>Providers</h3>
          {visibleProviders.length >= PROVIDER_LENGTH_DESKTOP ?
            <div className='provider_header__btn-wrapper'>        
              <div className='btn-round' >
                <Link to={`/allproviders/${version}/${gametype}`}>
                  <div className={`plusminus ${seeAll ? ' ' : 'active'}` } ></div>
                </Link>
              </div>
          </div>
          :
            ''
          }
        </div>
        {/* provider content for desktop*/}
        <div className={` provider-wrapper__flex carosuel `}>
            {/* <div className='provider-wrapper__flex inner-carousel' > */}
            <AnimatePresence >
              
                {visibleProviders.length>0 ?  
                    visibleProviders.map ((providerInfo)=> (
                      <div 
                        key={providerInfo.providerId} 
                        className="provider-wrapper__flex__card" 
                      >
                        
                        {providerInfo.providerImgUrl !== null && providerInfo.providerImgUrl !== '' ?
                          <Link 
                            to={`/provider/${providerInfo.providerId}/${providerInfo.providerDisplayName}/${version}/${gametype}`}
                            onClick={handleCloseSeeMore}
                          >
                            <img src={providerInfo.providerImgUrl} alt={providerInfo.providerDisplayName} />
                          </Link>
                         :
                         <Link 
                          to={`/provider/${providerInfo.providerId}/${providerInfo.providerDisplayName}/${version}/${gametype}`}
                          onClick={handleCloseSeeMore}  
                          >
                            <p>{providerInfo.providerDisplayName.substring(0, 10)}...</p>
                          </Link>
                         }
                      </div>
                      ))
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10vh', width:'100vw' }}>
                  <Spinner animation="border"/>
                </div>
                }
            </AnimatePresence>  
        </div>


      </div>

    ) : (
      /**********Content for Mobile**********/
      <div className="provider-wrapper">
        <div className="provider_header">
          <h3>Providers </h3>
          {mobileVisibleProviders.length >= PROVIDER_LENGTH_MOBILE ?
            (
              <div className='provider_header__btn-wrapper'>        
                <div className='btn-round '>
                  <Link to={`/allproviders/${version}/${gametype}`}>
                    <div className={`plusminus ${seeAll ? ' ' : 'active'}` } ></div>
                  </Link>
                </div>
            </div>
            )
          :
            ''
          }
        </div>
        {/* provider content for mobile*/}
        <AnimatePresence ref={carousel} className='carosuel' whileTap={{cursor: "grabbinng"}}>
          <motion.div drag="x" dragConstraints ={{right: 0, left: -width}} className='provider-wrapper__flex inner-carousel' >
              {mobileVisibleProviders.length>0 ?    
                  
                  mobileVisibleProviders.map ((providerInfo)=> (
                    <div 
                      key={providerInfo.providerId} 
                      className="provider-wrapper__flex__card" 
                    >
                      {providerInfo.providerImgUrl !== null && providerInfo.providerImgUrl !== '' ?
                          <Link 
                            to={`/provider/${providerInfo.providerId}/${providerInfo.providerDisplayName}/${version}/${gametype}`}
                            onClick={handleCloseSeeMore}
                          >
                            <img src={providerInfo.providerImgUrl} alt={providerInfo.providerDisplayName} />
                          </Link>
                         :
                         <Link 
                            to={`/provider/${providerInfo.providerId}/${providerInfo.providerDisplayName}/${version}/${gametype}`}
                            onClick={handleCloseSeeMore}
                          >
                            <p>{providerInfo.providerDisplayName.substring(0, 10)}..</p>
                          </Link>
                         }
                    </div>
                    ))
              :
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10vh', width:'100vw' }}>
              <Spinner animation="border"/>
            </div>}
          </motion.div>  
        </AnimatePresence>

      </div>
      )}
  

  

  </>
  )
}

export default ProvidersList