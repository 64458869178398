import { useEffect, useState } from 'react';
import { Timerhhmm } from '../Helper';


function Timer() {
  const [sec, setsec] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setsec(sec + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [sec]);

  return Timerhhmm(sec);
}

export default Timer;