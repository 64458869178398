/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import HttpService from '../../../utils/http.service';
import { Postdata } from '../../../utils/api.service';
import Loader from '../Loader';
import Alerts from '../Alert';
import { useDispatch } from 'react-redux';

const BecomeAgentModal = (props) => {
    const [validated, setValidated] = useState(false);
    const [validated_msg, setValidated_msg] = useState('Please enter the Email address');
    const [loader, setloader] = useState(false);
    const [message, setmessage] = useState({ type: 'danger', message: false });
    const [registerData, setregisterData] = useState({ name: '', email: '', mobile: '', location: '', description: '' });
    const dispatch = useDispatch();

    const onForgotAction = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            setmessage({ type: 'danger', message: false });
            setloader(true);
            Postdata(HttpService.becomeAgentUrl, registerData, dispatch).then((result) => {
                if (result.status === 200) {
                    setmessage({ type: 'success', message: result.message });
                    props.isAgenttrue();
                } else {
                    setmessage({ type: 'danger', message: result.message });
                    setValidated_msg(result.message);
                    setTimeout(() => {
                        setValidated(false);
                        setValidated_msg('Please enter the Email address');
                    }, 1500);
                }
                setloader(false);
            }).catch((e) => {
                setmessage({ type: 'danger', message: e.message });
                setloader(false);
                setValidated_msg(e.message);
                setTimeout(() => {
                    setValidated(false);
                    setValidated_msg('Please enter the Email address');
                }, 1500);
            });
        }
        setValidated(true);
    };

    return (
        <div className="igame-modal">
            <div className='row'>
                <div className='col-md-6'>
                    <div className='leftSidebar'>
                        <img src={require('../../../assets/igame-logo.png')} alt="" />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='rightSidebar'>
                        <h3 className='text-white'>Become An Agent</h3>

                        {message.message &&
                            <Alerts variant={message.type}>
                                {message.message}
                            </Alerts>
                        }

                        <Form noValidate validated={validated} autoComplete="off" method='get' action="/" onSubmit={onForgotAction}>
                            <Form.Group controlId="Form.ControlInput.1" className="custom-input">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoFocus
                                    value={registerData.name}
                                    onChange={(e) => setregisterData({ ...registerData, name: e.target.value })}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">Please enter the Full name.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="Form.ControlInput.2" className="custom-input">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    pattern="^[^ ]+@[^ ]+\.[a-z]{1,20}$"
                                    value={registerData.email}
                                    onChange={(e) => setregisterData({ ...registerData, email: e.target.value })}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{registerData.email.length ? 'Entered Email address is invalid' : validated_msg}.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="Form.ControlInput.2" className="custom-input">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    pattern="[0-9]{9,15}"
                                    value={registerData.mobile}
                                    onChange={(e) => setregisterData({ ...registerData, mobile: e.target.value })}
                                    onInvalid={e => e.target.setCustomValidity('Please enter valid mobile number')}
                                    onInput={e => e.target.setCustomValidity('')}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{registerData.mobile.length ? 'Entered mobile number is invalid' : 'Please enter the Mobile Number'}.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="Form.ControlInput.2" className="custom-input">
                                <Form.Label>Location</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={registerData.location}
                                    onChange={(e) => setregisterData({ ...registerData, location: e.target.value })}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">Please enter the Location.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="Form.ControlInput.2" className="custom-input">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={registerData.description}
                                    onChange={(e) => setregisterData({ ...registerData, description: e.target.value })}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">Please enter the Description.</Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                type='submit'
                                className='primary'
                                disabled={loader}
                            >
                                {loader ? <Loader fullscreen="forms" /> : 'Submit'}
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BecomeAgentModal;