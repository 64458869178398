/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import HttpService from '../../../utils/http.service';
import { Postdata } from '../../../utils/api.service';
import Loader from '../Loader';
import Alerts from '../Alert';
import { useDispatch } from 'react-redux';

const ContactUsModal = (props) => {
    const [validated, setValidated] = useState(false);
    const [validated_msg, setValidated_msg] = useState('Please enter the Email address');

    const [loader, setloader] = useState(false);
    const [email, setemail] = useState('');
    const [emailData, setEmailData] = useState({
        fullname: '',
        subject: '',
        description: ''
    })
    const [message, setmessage] = useState({ type: 'danger', message: false });
    const dispatch = useDispatch();

    const onContactUs = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            setmessage({ type: 'danger', message: false });
            setloader(true);
            Postdata(HttpService.contactUsUrl, {
                fullname : emailData.fullname,
                email: email,
                subject: emailData.subject, description: emailData.description
            }, dispatch).then((result) => {
                if (result.status === 200) {
                    setValidated(false);
                    setmessage({ type: 'success', message: result.message });
                    setemail('')
                    setEmailData({
                        subject: '',
                        description: ''
                    })
                    setloader(false);
                    setTimeout(() => {
                        props.loginRedirect();
                    }, 2000);
                } else {
                    setmessage({ type: 'danger', message: result.message });
                    setValidated_msg(result.message);
                    setloader(false);
                    setemail('')
                    setEmailData({
                        subject: '',
                        description: ''
                    })
                }
            }).catch((e) => {
                setmessage({ type: 'danger', message: e.message });
                setemail('');
                setEmailData({
                    subject: '',
                    description: ''
                })
                setValidated_msg(e.message);
                setloader(false);
                setTimeout(() => {
                    setValidated(false);
                    setValidated_msg('Please enter the Email address');
                }, 1500);
            });
        }
        setValidated(true);
    };

    return (
        <div className="igame-modal">
            <div className='row'>
                <div className='col-md-6'>
                    <div className='leftSidebar'>
                        <img src={require('../../../assets/igame-logo.png')} alt="" />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='rightSidebar'>
                        <h3 className='text-white'>Contact Us</h3>
                        <p>Please below mentioned details and click Send to contact us</p>

                        {message.message &&
                            <Alerts variant={message.type} className={message.type === 'danger' ? 'd-none' : ''}>
                                {message.message}
                            </Alerts>
                        }

                        <Form noValidate validated={validated} autoComplete="off" method='get' action="/" onSubmit={onContactUs}>
                            <Form.Group controlId="Form.ContactUsEmail" className="custom-input">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={emailData.fullname}
                                    onChange={(e) => setEmailData({
                                        ...emailData,
                                        fullname: e.target.value
                                    })}
                                    autoFocus
                                    required
                                />
                                    <Form.Control.Feedback type="invalid">{emailData?.fullname?.length ? '' : 'Full Name is Required.'}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="Form.ContactUsEmail" className="custom-input">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    pattern="^[^ ]+@[^ ]+\.[a-z]{1,20}$"
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                    autoFocus
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{email?.length ? 'Entered Email address is invalid' : validated_msg}.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="Form.ContactUsEmail" className="custom-input">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={emailData.subject}
                                    onChange={(e) => setEmailData({
                                        ...emailData,
                                        subject: e.target.value
                                    })}
                                    autoFocus
                                    required
                                />
                                 <Form.Control.Feedback type="invalid">{emailData?.subject?.length ? '' : 'Subject is Required.'}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="Form.ControlForgot" className="custom-input">
                                <Form.Label>Description</Form.Label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" value={emailData.description} onChange={(e) => setEmailData({
                                    ...emailData,
                                    description: e.target.value
                                })} autoFocus
                                    required />
                                    <Form.Control.Feedback type="invalid">{emailData?.description?.length ? '' : 'Description is Required.'}</Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                type='submit'
                                className='primary'
                                disabled={loader}
                            >
                                {loader ? <Loader fullscreen="forms" /> : 'Send'}
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsModal;