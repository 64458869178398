/* eslint-disable react/prop-types */

import iconTick from '../../../assets/agent-success.svg';
import { useEffect } from 'react';

const BecomeAgentSuccessModal = (props) => {
    useEffect(() => {
        const intervalId = setTimeout(() => {
            props.hidePopup();
        }, 5000);
        return () => clearTimeout(intervalId);
    }, [])

    return (
        <div className="igame-modal">
            <div className='row'>
                <div className='col-md-6'>
                    <div className='leftSidebar'>
                        <img src={require('../../../assets/igame-logo.png')} alt="" />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='rightSidebar d-flex flex-column align-items-center justify-content-center text-center'>
                        <img src={iconTick} />
                        <h4 className='text-white mt-4'>Your Details has been sent to Admin</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BecomeAgentSuccessModal;