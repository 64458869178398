/******************************************
 * Recieves data and displays in card format
 * ********************/
import React from 'react'
import axios from 'axios';
import { HeartFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'

import defaultThumb from '../../assets/game_thumb_nw.png'
import playIcon from '../../assets/play.svg'

import HttpService from '../../utils/http.service';
import './SingleGameCard.styles.scss';



const SingleGameCard = (props) => {
    
    //prop destructuring  
    const {gameId,gameDisplayName,iconImageUrl,provider} = props.singleGameInfo
    const {usersFavourites,handleGameIdFromChild,version}= props;

   //faveGamesIdLists array- stores all fav Games ID's alone
   // used to check all gameid and displays to red heart icon
   const favGamesIdLists = usersFavourites.map((singleFavGame)=>{
      return singleFavGame.gameId
    })
    
         
  //URL
    const setFavURL = (version === 'v1') ? HttpService.Live_Casino_game_set_fav :
                      (version === 'games') ? HttpService.EventBet_game_set_fav : null;
    
  //handles Fav button click
    const handleFavorite = (gameId) => {
      saveFavToDB(gameId) 
      handleGameIdFromChild(gameId)          
    };

  //API to backend to save it to DB
    const saveFavToDB = async (gameId)=>{
      try {
        const response = await axios.post(setFavURL,{gameId: gameId })
                
        if(response.status !== 200){
          throw new Error(`Request failed with status code ${response.status}`);
        }
        
      } catch (error) {
        console.error('An error occurred:', error.message);
        if (error.response) {
          console.error('Response status code:', error.response.status);
        }
      }
    }

  return (
    <div className='game_card_wrapper' id={gameId}>

        <div className="game_card_img_bg">
          <img src={iconImageUrl ?  iconImageUrl : defaultThumb } alt="Icon Img" />
        </div>

        <Link to={`/gamelaunch/${version}/${gameId}`}>
        <div className="game_card_info">
            <h5>{gameDisplayName}</h5>
            <p>{provider.name}</p>
        </div>
        </Link>

        <div className="game_card_info_fav">
          <div style={{cursor:"pointer"}} onClick={()=> handleFavorite(gameId)}>
            {favGamesIdLists.some((favorite) => favorite == gameId) ? (
              <HeartFill color="crimson" />
            ) : (
              <HeartFill color="white" />
            )}
          </div>          
        </div>

      
      <Link to={`/gamelaunch/${version}/${gameId}`}>
        <div className="game_card_play">
          <div className="play_icon">
            <img src={playIcon} alt="playicon_igame365" />
          </div>
        </div>
      </Link>


    </div>
  )
}

export default SingleGameCard