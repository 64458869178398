/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from "../redux/selectors/authSelector";
import { verifyToken } from "../redux/actions/authActions";
import Loader from '../components/common/Loader';
import { Container } from 'react-bootstrap';
import { useEffect } from 'react';

const PrivateRoute = ({ Component, size }) => {
  const dispatch = useDispatch();
  const auth = useSelector(isAuthenticated);

  useEffect(() => {
    let timer = 60000;
    if (window.location.pathname.includes('/game')) timer = 10000;
    const intervalId = setInterval(() => {
      dispatch(verifyToken());
    }, timer);
    return () => clearInterval(intervalId);
  }, [window.location.pathname]);

  if (!auth && window.localStorage.getItem('authToken')) {
    return <Loader fullscreen="loader_fullscreen" />;
  }

  return auth ?
    <>
      <Container className={size ? size : null}>
        <Component />
      </Container>
    </>
    : <Navigate to="/" state={{ login: true }} />
}

export default PrivateRoute;
