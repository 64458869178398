import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { PersonPlusFill } from 'react-bootstrap-icons';

import defaultThumb from '../../assets/game_thumb_nw.png'
import playIcon from '../../assets/play.svg'
import './searchResultCard.styles.scss';

const SearchResultCard = (props) => {
  // props destrucring
    const { gameId,
            gameDisplayName,
            iconImageUrl,
            provider,
            user_id,
            username, 
            profile_pic
        } = props.searchData
//    console.log('props :>> ', props); remove after testing

//State 
const [popupUserData, setPopupUserData] = useState({});

useEffect(()=>{
  if(props.searchData){
    // console.log('props.searchData :>> ', props.searchData); // TODO - remove after test
    setPopupUserData(props.searchData)
  }
},[])

const handleFriendRequest = (userData)=>{
  // console.log('userData :>> ', userData); //remove - post testing

  if (userData._id) {
    if (userData.is_request_received) {
      props.showPopupAcceptReject(userData);
    } else if (userData.is_friend) {
      props.popupCallbacks.showViewPopup(userData);
    } else if (userData.is_request_sent) {
      props.popupCallbacks.showFriendPopupCancel(userData);
    } else {
      props.popupCallbacks.showFriendPopup(userData);
    }
  }
}



 return (
    <>
    {/* Shows differnt card results based on gameId and user_id using if loop */}
    {gameId ?

      <div className='search_card_wrapper_games' id={gameId}>
          <div className="search_card_img_bg">
              <img src={iconImageUrl? iconImageUrl : defaultThumb} alt="Icon Img"  />
          </div>
          <div className="search_card_info">
              <h5>{gameDisplayName}</h5>
              <p>{provider.name}</p>
          </div>
          <Link to={`/gamelaunch/${gameId}`} onClick={props.popupCallbacks.closePopupOnGameLaunch}>
            <div className="search_card_play">
              <div className="play_icon">
                <img src={playIcon} alt="igame365_playicon" />
              </div>
            </div>
          </Link>
      </div>

       : 
    
    <div className="search_card_wrapper_users" id={user_id}>
        <div className="search_card_img_bg">
          {/* {console.log(profile_pic)} */}
              <img src={profile_pic? profile_pic : defaultThumb} alt="Icon Img"  />
        </div>
        <div className="search_card_info">
          <div className="search_card_info_name">
            <h5>{username}</h5>
            {/* <p>{name}</p> */}
          </div>
          <div className="search_card_info_button" onClick={()=>handleFriendRequest(popupUserData)}>
            <PersonPlusFill color="white"/>
          </div>
        </div>
    </div>
    
    }
    
    </>
  )
}

export default SearchResultCard