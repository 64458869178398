/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/Index.scss';
// import i18next from "i18next";
import DefaultRoutes from "./routes/routes";
import { Socket } from './components/Socket';
import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    console.log('Something went wrong', error);
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return <h1>Oops, something went wrong...</h1>;
    }
    return this.props.children;
  }
}

function App() {
  // i18next.changeLanguage("hi");
  // console.log(i18next.languages);

  return (
    <ErrorBoundary>
      <div className="App">
        <div className="app-bg" />
        <DefaultRoutes />
        <Socket />
      </div>
    </ErrorBoundary>
  );
}

export default App;
